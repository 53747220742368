import styled, { css } from 'styled-components';
import textInputIcon from '../../../assets/images/text-input-icons.svg?react';
import emailInputIcon from '../../../assets/images/email-icons.svg?react';
import errorTriangle from '../../../assets/images/error_triangle.svg?react';
import InfoIcons from '../../../assets/images/info-icons.svg?react';
import employedInputIcon from '../../../assets/images/employed-icon.svg?react';
import eyeSlashIcon from '../../../assets/images/eye-slash.svg?react';
import eyeIcon from '../../../assets/images/eye.svg?react';

export type TextInputProps = {
  size?: string;
  $hasError?: boolean;
  $focused?: boolean;
  $type_0f_icon?: string;
  $hasIcon?: boolean;
  $isAddressIcon?: boolean;
  $isHidden?: boolean;
  $iconColor?: string;
  disabled?: boolean;
  $hasAsterisk?: boolean;
  $isEstimatedEquity?: boolean;
  $padding?: string;
  $isCentered?: boolean;
};

const IconStyles = css`
  width: 1.75rem;
  height: 1.75rem;
  path {
    width: 1.25rem;
    fill: ${({ theme }) => theme.colors.borderColor};
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;

const FontStyles = css`
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Poppins;
`;

const AlignText = css`
  display: flex;
  align-items: center;
`;

export const Container = styled.div<TextInputProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  min-width: ${({ size }) => size};
  flex-grow: 1;
  ${({ $hasAsterisk }) =>
    $hasAsterisk &&
    `
      label::after {
        content: '*';
        color: red;
        visibility: visible;
      }
    `}
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 20rem;
    min-width: 12rem;
    flex-shrink: revert;
  }
`;
export const Label = styled.label<TextInputProps>`
  display: block;
  font-weight: bold;
  font-size: 1.063rem;
  ${FontStyles};
  color: ${({ theme }) => theme.colors.bgGrey};
  flex-direction: row;
  ${AlignText};
  justify-content: space-between;
  width: ${({ $hasAsterisk }) => ($hasAsterisk ? 'max-content' : '100%')};
`;

export const ContainerInfoIcon = styled.div`
  height: 50%;
  ${AlignText};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: 0.1rem;
    align-items: stretch;
  }
`;

export const InfoIcon = styled(InfoIcons)<TextInputProps>`
  width: 1.18rem;
  height: 1.18rem;
  margin-left: 1rem;
  cursor: pointer;
  path {
    fill: ${({ $iconColor, theme }) => ($iconColor ? $iconColor : theme.colors.primary)};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 0.3rem;
  }
`;
export const TextIcon = styled(textInputIcon)`
  ${IconStyles};
`;

export const EmploymentIcon = styled(employedInputIcon)`
  ${IconStyles};
  path {
    fill: ${({ theme }) => theme.colors.primary} !important;
  }
`;

export const EyeSlash = styled(eyeSlashIcon)`
  ${IconStyles};
  width: 1.2rem;
  height: 1.2rem;
  path {
    fill: ${({ theme }) => theme.colors.primary} !important;
  }
`;

export const Eye = styled(eyeIcon)`
  ${IconStyles};
  width: 1.2rem;
  height: 1.2rem;
  path {
    fill: ${({ theme }) => theme.colors.primary} !important;
  }
`;

export const EmailIcon = styled(emailInputIcon)`
  ${IconStyles};
`;

export const Input = styled.input<TextInputProps>`
  ${AlignText};
  padding: ${({ $hasIcon, $isAddressIcon, $padding }) => ($padding ? $padding : $hasIcon || $isAddressIcon ? '0' : '1.375rem')};
  flex: 1;
  gap: 0.625rem;
  background: ${({ theme }) => theme.colors.bgWhite};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.textLightGrey : theme.colors.textGrey)};
  font-family: Poppins;
  flex-grow: 1;
  font-size: 1.188rem;
  font-weight: 300;
  line-height: normal;
  border: none;
  box-sizing: border-box;
  width: 1rem;
  text-align: ${({ $isCentered }) => ($isCentered ? 'center' : 'left')};
  &:focus {
    outline: none;
    text-align: ${({ $isCentered }) => ($isCentered ? 'center' : 'left')};
  }
`;

export const ErrorMessage = styled.span`
  display: flex;
  font-size: 0.625rem;
  ${FontStyles};
  color: ${({ theme }) => theme.colors.errorColor};
`;

export const InputContainer = styled.div<TextInputProps>`
  position: relative;
  ${AlignText};
  border-radius: 1.875rem;
  border: 0.063rem solid;
  overflow: hidden;
  width: 100%;
  height: 3.125rem;
  border-color: ${({ $hasError, $focused, theme }) => ($hasError ? theme.colors.errorColor : $focused ? theme.colors.secondary : theme.colors.borderColor)};
  input::placeholder {
    text-align: ${({ $isCentered, $isEstimatedEquity }) => ($isCentered || $isEstimatedEquity ? 'center' : 'start')};
  }
  input:focus {
    text-align: ${({ $isCentered, $isEstimatedEquity }) => ($isCentered || $isEstimatedEquity ? 'center' : 'start')};
  }
  input {
    text-align: ${({ $isCentered, $isEstimatedEquity }) => ($isCentered || $isEstimatedEquity ? 'center' : 'start')};
  }
`;

export const IconContainer = styled.div<TextInputProps>`
  position: relative;
  ${AlignText};
  pointer-events: none;
  width: 3rem;
  justify-content: center;
  margin: 0.6rem;
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const IconEyeContainer = styled.div<TextInputProps>`
  width: 2rem;
  margin: 0.9rem;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const ErrorIcon = styled(errorTriangle)`
  width: 0.75rem;
  height: 0.75rem;
  path {
    width: 0.25rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;
