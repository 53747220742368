import React, { useState, useEffect } from 'react';
import { Input, InputContainer } from '../TextInput/TextInput.styled';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { DropDownListContainer, DropDownList, ListItem } from '../DropDownInput/DrowDownInput.styles';
import { Container, ErrorMessage, IconContainer, AddressIcon, ErrorIcon } from './AddressAutoComplete.styled';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import _ from 'lodash';
import { formatOccupancyDate } from '@/utils/handleDate';

export interface AddressAutoCompleteProps {
  error?: string;
  size?: string;
  isDirection?: boolean;
  value?: any;
  typeOfIcon?: string;
  isAddressIcon?: boolean;
  iconColor?: string;
  propertyType?: string;
  isMailingAddress?: boolean;
  onChange?: (value: string, propertyType?: any, index?: number) => void;
}

interface AddressComponent {
  types: string[];
  short_name?: string;
}

interface FormattedAddress {
  streetAddress: string;
  city: string;
  state: string;
  county?: string;
  zip: string;
  latitude: number;
  longitude: number;
  streetNumber?: string;
  route?: string;
  locality?: string;
  stateOrProvince?: string;
  country?: string;
  postalCode?: string;
  occupancyDate?: string;
}

export const parseAddress = (addressComponents: AddressComponent[]): Partial<FormattedAddress> => {
  const findComponent = (type: string) => addressComponents?.find((component) => component?.types?.includes(type));

  const streetNumber = findComponent('street_number');
  const route = findComponent('route');
  const locality = findComponent('locality') || findComponent('administrative_area_level_3');
  const stateOrProvince = findComponent('administrative_area_level_1');
  const county = findComponent('administrative_area_level_2');
  const country = findComponent('country');
  const postalCode = findComponent('postal_code');

  return {
    streetNumber: streetNumber?.short_name,
    route: route?.short_name,
    locality: locality?.short_name,
    stateOrProvince: stateOrProvince?.short_name,
    county: county?.short_name,
    country: country?.short_name,
    postalCode: postalCode?.short_name,
  };
};

export const AddressAutoComplete: React.FC<AddressAutoCompleteProps> = ({
  size = '12rem',
  isDirection = false,
  value = '',
  typeOfIcon,
  iconColor,
  isMailingAddress = false,
  propertyType = 'text',
  onChange,
}) => {
  const [address, setAddress] = useState(value);
  const [focused, setFocused] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [visited, setVisited] = useState(false);
  const isRequired = true;
  const dispatch = useAppDispatch();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const hasIcon = typeOfIcon === 'address';
  const currentForm = useAppSelector((state) => state.CurrentForm);
  const isHidden = templateConfig?.pages?.LetsGetStarted?.consumerInformation?.addressSearch?.isHidden;
  const { isResume } = useAppSelector((state) => state.currentPage);
  const { propertyStreetAddress } = loanApplication?.borrowerProperty || {};
  const { address: addressForm, city, state, zip } = currentForm;

  useEffect(() => {
    if (!focused && visited) {
      setInputError(isRequired && visited && !validated);
    }
  }, [focused, validated, visited, isRequired]);

  const onBlur = () => {
    setFocused(false);
    setValidated(address?.length > 0);
  };
  const handleChange = (address: string) => {
    setAddress(address);
    if (onChange) {
      onChange(address, propertyType);
    }
  };

  useEffect(() => {
    if (addressForm && city && state && zip) {
      handleAddressSelect(`${addressForm},${city},${state},${zip}`, '');
    }
  }, [addressForm, city, state, zip]);

  const handleFocus = () => {
    setFocused(true);
    setVisited(true);
  };

  const addressIsComplete = (addressObject: Partial<FormattedAddress>) => Object.values(addressObject).every((value) => value);

  const handleAddressSelect = async (selection: string, placeId: string) => {
    try {
      const results = await geocodeByAddress(selection);
      let coordinates = await getLatLng(results[0]);
      let addressObject = parseAddress(results[0].address_components);
      if (!addressIsComplete(addressObject)) {
        const resultsByAddress = await geocodeByPlaceId(placeId);
        const alternativeAddressObject = parseAddress(resultsByAddress[0].address_components);

        if (addressIsComplete(alternativeAddressObject)) {
          coordinates = await getLatLng(resultsByAddress[0]);
          addressObject = alternativeAddressObject;
        }
      }

      const streetAddress = addressObject.streetNumber && addressObject.route ? `${addressObject.streetNumber} ${addressObject.route}` : selection.split(', ')[0] || '';
      const formattedAddress: FormattedAddress = {
        streetAddress,
        city: addressObject.locality || '',
        state: addressObject.stateOrProvince || '',
        country: addressObject.county || '',
        zip: addressObject.postalCode || '',
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        occupancyDate: addressObject.occupancyDate,
      };
      const occupancyDateFormat = formattedAddress?.occupancyDate ? formatOccupancyDate(formattedAddress?.occupancyDate) : '';
      let auxLoanApplication = _.cloneDeep(loanApplication);
      if (isMailingAddress) {
        auxLoanApplication.mailingAddress = {
          mailingStreetAddress: formattedAddress?.streetAddress || '',
          mailingStreetAddress2: '',
          mailingCity: formattedAddress?.city || '',
          mailingState: formattedAddress?.state || '',
          mailingCountry: 'United States',
          mailingCounty: formattedAddress?.country || '',
          mailingZip: formattedAddress?.zip || '',
        };
      } else {
        [
          (auxLoanApplication.borrowerProperty = {
            propertyStreetAddress: formattedAddress?.streetAddress || '',
            propertyCity: formattedAddress?.city || '',
            propertyState: formattedAddress?.state || '',
            propertyCountry: 'United States',
            propertyCounty: formattedAddress?.country || '',
            propertyZip: formattedAddress?.zip || '',
            propertyLatitude: formattedAddress?.latitude || 0,
            propertyLongitude: formattedAddress?.longitude || 0,
            propertyOccupancyDate: occupancyDateFormat,
            city: formattedAddress?.city || '',
            state: formattedAddress?.state || '',
            zip: formattedAddress?.zip || '',
          }),
          (auxLoanApplication.mailingAddress = {
            mailingStreetAddress: formattedAddress?.streetAddress || '',
            mailingStreetAddress2: '',
            mailingCity: formattedAddress?.city || '',
            mailingState: formattedAddress?.state || '',
            mailingCountry: 'United States',
            mailingCounty: formattedAddress?.country || '',
            mailingZip: formattedAddress?.zip || '',
          }),
          (auxLoanApplication.propertyIsMailingAddress = true),
        ];
      }
      const payload = isMailingAddress ? auxLoanApplication.mailingAddress : auxLoanApplication.borrowerProperty;
      dispatch({ type: 'CurrentForm/addField', payload: payload });

      dispatch({
        type: 'LoanApplication/updateValue',
        payload: { ...auxLoanApplication },
      });
      placeId?.length && setAddress(streetAddress);
    } catch (error) {
      let auxLoanApplication = _.cloneDeep(loanApplication);

      auxLoanApplication.borrowerProperty = {
        ...auxLoanApplication.borrowerProperty,
        propertyStreetAddress: addressForm || '',
        propertyCity: city || '',
        propertyState: state || '',
        propertyCountry: 'United States',
        propertyZip: zip || '',
        propertyLatitude: 0,
        propertyLongitude: 0,
        city,
        state,
        zip,
      };

      dispatch({
        type: 'LoanApplication/updateValue',
        payload: { ...auxLoanApplication },
      });

      console.error('Error selecting address:', error);
    }
  };
  useEffect(() => {
    setAddress(propertyStreetAddress || currentForm?.address || '');
  }, [isResume]);

  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleAddressSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Container $isDirection={isDirection} size={size} $isHidden={isHidden}>
          <InputContainer $hasError={inputError}>
            {typeOfIcon && <IconContainer>{<AddressIcon $iconColor={iconColor} />}</IconContainer>}
            <Input
              {...getInputProps({
                className: 'location-search-input',
                onFocus: handleFocus,
                onBlur: onBlur,
              })}
              placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.addressSearch?.text || 'Search for property address'}
              size={size}
              $hasError={inputError}
              $focused={focused}
              data-testid="address-input"
              value={address}
              $isAddressIcon={hasIcon}
            />
          </InputContainer>
          {inputError && (
            <ErrorMessage>
              <ErrorIcon /> must provide an address
            </ErrorMessage>
          )}

          {suggestions && suggestions.length > 0 && (
            <>
              <DropDownListContainer>
                <DropDownList size={size} $hasError={false} data-testid="address-drop-down">
                  {loading && <div>Loading...</div>}
                  {suggestions?.map((suggestion) => {
                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                    const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <ListItem data-testid="address-list" {...getSuggestionItemProps(suggestion, { className, style })} key={suggestion.placeId}>
                        {suggestion.description}
                      </ListItem>
                    );
                  })}
                </DropDownList>
              </DropDownListContainer>
              ,
            </>
          )}
        </Container>
      )}
    </PlacesAutocomplete>
  );
};
