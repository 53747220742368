import React, { useState, useEffect } from 'react';
import { ButtonStyles, ButtonContainer, ArrowIcon } from './NavigationButton.styles';
import { handleNext } from '@helper/navigationHelpers';
import { ModalSubmit } from '../../Modals/ModalSubmit/ModalSubmit';
import { pages } from '../../../defaultVerbiages';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import _ from 'lodash';
import { ConfirmationModal } from '@/components/Modals/ConfirmModal/ConfirmModal';
import { decrementStep } from '@/reducers/currentPage';
import { formatOccupancyDate } from '@/utils/handleDate';
import {
  handleGetStarted,
  handleGetStartedAddress,
  executeAction,
  handleRequestVmo,
  handleRequestEmploymentDetails,
  handleRequestPersonalInformation,
  handleGovernmentInformation,
  handleRequestDeclarations,
} from '@/utils/handleEndpoints';
import { startLoading, stopLoading, selectIsLoading } from '@/reducers/currentPage';
import { DefaultModal } from '@Modals/DefaultModal/DefaultModal';
import { TextBlock } from '@/components/text/TextBlock';
import CodeInput from '@/components/input/CodeInput/CodeInput';
import { TypeOfText } from '@/components/text/TextBlock';
import { useIsMobile } from '@/hooks/useIsMobile';

export enum PageOrigin {
  GET_STARTED = 'get-started',
  GET_STARTED_ADDRESS = 'get-started-address',
  ESTIMATED_AVAILABLE_EQUITY = 'estimated-available-equity',
  VMO = 'vmo',
  VMO_LINE_AMOUNT = 'vmo-line-amount',
  VMO_DRAW_AMOUNT = 'vmo-draw-amount',
  PERSONAL_INFORMATION = 'personal-information',
  EMPLOYMENT_INFORMATION = 'employment-information',
  GOVERNMENT_MONITORING_INFORMATION = 'government-monitoring-information',
  DECLARATIONS = 'declarations',
  INQUIRY_SUMMARY = 'inquiry-summary',
  COMPLETION = 'completion',
}
export interface NavigationButtonProps {
  nextButton: string;
  isBackButton: boolean;
  backButton: string;
  variant: 'primary' | 'secondary' | 'tertiary';
  type?: string;
  disabled: boolean;
  buttonSize?: 's' | 'm' | 'l';
  icon?: any;
  navigation?: (page: any) => void;
  onAccess: (page: string) => void;
  pageOrigin?: PageOrigin;
  destinationPage?: PageOrigin;
  currentPage?: PageOrigin;
  hasIcon?: boolean;
  isCustomColor?: boolean;
  customColor?: string;
  customColorBorder?: string;
  customColorText?: string;
  isSummaryPage?: boolean;
  infoModal?: {
    header?: string;
    body?: string;
    label?: string;
    button?: string;
  };
}

export interface CurrentFormProps {
  firstName: string;
  middleName: string;
  lastName: string;
}

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  nextButton,
  variant,
  buttonSize = 's',
  isBackButton,
  backButton,
  type,
  disabled = false,
  onAccess,
  navigation,
  pageOrigin,
  currentPage,
  destinationPage,
  hasIcon = false,
  isCustomColor,
  customColor,
  customColorBorder,
  customColorText,
  isSummaryPage = false,
}) => {
  const [openModal, setOpenModal] = useState(false);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let currentForm = useAppSelector((state) => state.CurrentForm);
  const isFirstPage = !hasIcon;
  const isGetStarted = currentPage === 'get-started';
  const dispatch = useAppDispatch();
  const isICE = templateConfig?.isICE ?? false;
  const isLoanDepot = templateConfig?.lenderName === 'Loan Depot';
  const isLoading = useAppSelector(selectIsLoading);
  const isVmoV2 = templateConfig?.features?.vmoRedesign?.isEnabled ?? false;
  const [errorResponse, setErrorResponse] = useState<string | unknown>('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  let auxLoanApplication = _.cloneDeep(loanApplication);
  const frontProgramType = loanApplication?.selectedProgramType === 'Line of Credit' ? 'helocData' : 'helData';
  const isHelProgram = frontProgramType === 'helData' || isICE;
  const hasDrawAmountPage = (templateConfig?.pages?.hasDrawAmountPage || !isHelProgram) ?? false;
  const isVmoLineOrDraw = hasDrawAmountPage ? currentPage === 'vmo-draw-amount' : currentPage === 'vmo-line-amount';
  const isMobile = useIsMobile(780);
  const isResumeV2 = templateConfig?.features?.resume?.isEnabled ?? true;
  const [isCodeInput, setIsCodeInput] = useState(false);
  const [openResume, setOpenResume] = useState(false);
  const isSkipRequest = templateConfig?.pages?.LetsGetStarted?.skipRequest ?? false;

  useEffect(() => {
    if (errorResponse) {
      setErrorResponse(errorResponse);
      setOpenErrorModal(true);
    }
  }, [errorResponse, setErrorResponse]);

  useEffect(() => {
    if (currentPage === 'get-started') {
      auxLoanApplication.borrowers[0].borrowerEmailAddress = currentForm.email;
    }
    dispatch({
      type: 'LoanApplication/updateValue',
      payload: { ...auxLoanApplication },
    });
  }, [currentForm.email]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = async (action: string) => {
    if (type === 'submit' && action !== 'back') {
      if (currentPage === 'get-started') {
        const occupancyDateFormat = currentForm?.dataOccupancyDate ? formatOccupancyDate(currentForm.dataOccupancyDate) : '';

        const updatedBorrower = {
          ...auxLoanApplication.borrowers[0],
          borrowerLoanPurpose: currentForm.purposeOfLoan,
          borrowerFirstName: currentForm.firstName,
          borrowerMiddleName: currentForm.middleName,
          borrowerLastName: currentForm.lastName,
          borrowerEmailAddress: currentForm.email,
        };
        const updateConsent = {
          ...auxLoanApplication.consent,
          softPullConsent: currentForm?.softPullConsent ?? false,
          eConsent: currentForm.eConsent,
        };
        const updateProperty = {
          ...auxLoanApplication.borrowerProperty,
          propertyStreetAddress2: currentForm.mailingSuit,
          propertyOccupancyDate: occupancyDateFormat,
        };
        auxLoanApplication.propertyPrimaryUse = currentForm.primaryUse;
        auxLoanApplication.propertyType = currentForm.propertyType;
        auxLoanApplication.borrowers[0] = updatedBorrower;
        auxLoanApplication.borrowerProperty = updateProperty;
        auxLoanApplication.consent = updateConsent;
        setOpenModal(true);
      }

      dispatch({
        type: 'LoanApplication/updateValue',
        payload: { ...auxLoanApplication },
      });
      setOpenModal(true);
    } else {
      const page = action === 'next' ? destinationPage : pageOrigin;
      if (action !== 'next') {
        dispatch(decrementStep());
      }
      if (!navigation) return;
      setOpenModal(false);

      if (currentPage === PageOrigin.GET_STARTED) {
        const auxLoanApplication = _.cloneDeep(loanApplication);
        auxLoanApplication.borrowerProperty = {
          ...auxLoanApplication.borrowerProperty,
          propertyZip: currentForm.zip || '',
        };
        dispatch({
          type: 'LoanApplication/updateValue',
          payload: { ...auxLoanApplication },
        });
      }

      if (!isLoading && !openErrorModal) handleNext(navigation, onAccess, currentPage!, `/${page}`);
    }
  };

  const processClick = async () => {
    let auxLoanApplication = { ...loanApplication };
    dispatch(startLoading());
    try {
      if (currentPage === PageOrigin.DECLARATIONS) {
        await handleRequestDeclarations(isICE, auxLoanApplication, currentForm, dispatch, templateConfig);
      }

      if (currentPage === PageOrigin.GET_STARTED) {
        if (!isSkipRequest) {
          const response = await handleGetStarted(isLoanDepot, auxLoanApplication, dispatch);
          if (response && response.data.isEligible && !isResumeV2) {
            setOpenModal(false);
            setIsCodeInput(true);
            setOpenResume(true);
          }
        }
      }

      if (currentPage === PageOrigin.GET_STARTED_ADDRESS) {
        await handleGetStartedAddress(isICE, auxLoanApplication, loanApplication, dispatch, templateConfig);
      }

      if (currentPage === PageOrigin.ESTIMATED_AVAILABLE_EQUITY) {
        if (auxLoanApplication.estimatedHomeEquity < 50000) {
          auxLoanApplication.lineAmount = 35000;
          auxLoanApplication.minLineAmount = 35000;
          auxLoanApplication.drawAmount = 35000;
          auxLoanApplication.fixedLineAmount = 35000;
        } else {
          auxLoanApplication.lineAmount = 50000;
          auxLoanApplication.minLineAmount = 50000;
          auxLoanApplication.minDrawAmount = 37500;
          auxLoanApplication.maxDrawAmount = 50000;
        }
        await executeAction(isICE, auxLoanApplication, dispatch, templateConfig, false);
      }

      if (isVmoLineOrDraw || (isVmoV2 && currentPage === PageOrigin.VMO)) {
        await handleRequestVmo(isICE, auxLoanApplication, dispatch, templateConfig);
      }

      if (currentPage === PageOrigin.EMPLOYMENT_INFORMATION) {
        await handleRequestEmploymentDetails(isICE, auxLoanApplication, dispatch, templateConfig);
      }

      if (currentPage === PageOrigin.PERSONAL_INFORMATION) {
        await handleRequestPersonalInformation(isICE, auxLoanApplication, dispatch, templateConfig);
      }

      if (currentPage === PageOrigin.GOVERNMENT_MONITORING_INFORMATION) {
        await handleGovernmentInformation(isICE, auxLoanApplication, dispatch, templateConfig);
      }
    } catch (error) {
      console.error(`Error on ${currentPage} request:`, error);
      setErrorResponse(error);
      throw error;
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <>
      <ButtonContainer $variant={variant} $buttonSize={buttonSize} $isGetStarted={isGetStarted} $isMobile={isMobile}>
        {!isBackButton && (
          <ButtonStyles
            $variant={'tertiary'}
            onClick={() => handleClick('back')}
            $buttonSize={buttonSize}
            $isCustomColor={isCustomColor}
            $customColor={customColor}
            $customColorBorder={customColorBorder}
            $customColorText={customColorText}
          >
            {backButton}
            {!isFirstPage && <ArrowIcon />}
          </ButtonStyles>
        )}
        {!isMobile && isSummaryPage && (
          <>
            <TextBlock
              text={templateConfig?.pages?.summary?.programInformationLink?.textOne?.text}
              direction={'center'}
              color="primary"
              type={TypeOfText.H4}
              isHidden={templateConfig?.pages?.summary?.programInformationLink?.textOne?.isHidden ?? false}
            />
            <TextBlock
              text={templateConfig?.pages?.summary?.programInformationLink?.textTwo?.text}
              direction={'center'}
              color="primary"
              type={TypeOfText.H4}
              isHidden={templateConfig?.pages?.summary?.programInformationLink?.textTwo?.isHidden ?? false}
            />
          </>
        )}
        <ButtonStyles
          disabled={disabled}
          $variant={variant}
          onClick={async () => {
            await processClick();
            handleClick('next');
          }}
          $buttonSize={buttonSize}
          $isCustomColor={isCustomColor}
          $customColor={customColor}
          $customColorBorder={customColorBorder}
          $customColorText={customColorText}
        >
          {nextButton}
          {!isFirstPage && <ArrowIcon />}
        </ButtonStyles>
        {isMobile && isSummaryPage && (
          <>
            <TextBlock
              text={templateConfig?.pages?.summary?.programInformationLink?.textOne?.text}
              direction={'center'}
              color="primary"
              type={TypeOfText.H4}
              isHidden={templateConfig?.pages?.summary?.programInformationLink?.textOne?.isHidden ?? false}
            />
            <TextBlock
              text={templateConfig?.pages?.summary?.programInformationLink?.textTwo?.text}
              direction={'center'}
              color="primary"
              type={TypeOfText.H4}
              isHidden={templateConfig?.pages?.summary?.programInformationLink?.textTwo?.isHidden ?? false}
            />
          </>
        )}
      </ButtonContainer>
      {isLoading && <ModalSubmit openModal={openModal} setOpenModal={setOpenModal} infoSubmitModal={pages.LetsGetStarted.modals.infoSubmitModal} onAccess={onAccess} />}
      {openModal && !isSummaryPage && !openResume ? (
        <ModalSubmit openModal={openModal} setOpenModal={setOpenModal} infoSubmitModal={pages.LetsGetStarted.modals.infoSubmitModal} onAccess={onAccess} />
      ) : isCodeInput ? (
        <CodeInput isResume={openResume} setIsResume={setOpenResume} loanApplication={loanApplication} onAccess={onAccess} />
      ) : (
        <ConfirmationModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          infoModal={templateConfig?.pages?.summary?.modalSubmit?.infoSubmitModal || pages?.summary?.modalSubmit?.infoSubmitModal}
          isSummaryPage={isSummaryPage}
          onAccess={onAccess}
          navigation={navigation}
        />
      )}
      {openErrorModal && errorResponse && (
        <DefaultModal
          openModal={openErrorModal}
          setOpenModal={setOpenErrorModal}
          isErrorModal={true}
          infoModal={{ body: errorResponse as string, button: 'Return to %%lenderName%%' }}
        />
      )}
    </>
  );
};
