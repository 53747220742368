export const formatToTitleCase = (label: string) => {
  const text = label
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/[_-]/g, ' ')
    .split(' ')
    .filter(Boolean)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  return text;
};

const capitalizeWords = (str: string) => str.replace(/\b\w/g, (char) => char.toUpperCase());
export const transformGovernmentText = (array: any) => {
  const transformedString = array
    .map((item: string) => {
      const [label, value] = item.split('|');

      const transformedLabel = capitalizeWords(label.replace(/([a-z])([A-Z])/g, '$1 $2'));
      return value ? `${transformedLabel}: ${value}` : transformedLabel;
    })
    .join(', ');
  return transformedString;
};
