import styled from 'styled-components';

interface LineAmountPage {
  $isActive?: boolean;
  $isHidden?: boolean;
}

export const ProgressbarWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1.25rem;

  @media (max-width: 48rem) {
    margin-bottom: 2.5rem;
  }
`;

export const Progressbar = styled.div<LineAmountPage>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 0.5rem;
`;

export const Line = styled.div`
  width: 1.875rem;
  height: 0.125rem;
  border-top: 0.1875rem solid #000000;
  margin: 0 1.25rem;
`;

export const ProgressbarItem = styled.li<LineAmountPage>`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  min-width: 3.0625rem;
  height: 3.0625rem;
  text-align: center;
  background-color: #a6ce39;
  color: var(--white);
  border-radius: 1.5625rem;
  font-weight: 600;
  font-size: 1.25rem;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
  position: relative;

  p {
    display: flex;
    width: 6.875rem;
    position: absolute;
    top: 3.4375rem;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    text-align: center;
    color: #5e6572;
  }
`;
