import React from 'react';
import CompletionCongratulations from '@/components/CompletionCongratulations/CompletionCongratulations';
import { Wrapper } from './Completion.styles';
import NextStepsButton from '@/components/Button/NextSteps/NextStepsButton';
import CompletionV2 from '@/components/CompletionV2/CompletionV2';
import { useAppSelector } from '@app/hooks';

const Completion: React.FC = () => {
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const isLoanDepot = loanApplication?.lenderName === 'loanDepot';
  const isCompletionV2 = templateConfig?.features?.completionV2?.isEnabled;
  return (
    <Wrapper data-testid="wrapper-container">
      {isLoanDepot || isCompletionV2 ? (
        <CompletionV2 />
      ) : (
        <>
          <CompletionCongratulations />
          <NextStepsButton />
        </>
      )}
    </Wrapper>
  );
};

export default Completion;
