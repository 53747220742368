export const pageListOrder = [
  'get-started',
  'get-started-address',
  'estimated-available-equity',
  'vmo',
  'vmo-line-amount',
  'vmo-draw-amount',
  'personal-information',
  'employment-information',
  'government-monitoring-information',
  'declarations',
  'inquiry-summary',
  'completion',
];

export const pages = {
  isICE: false,
  hasDrawAmountPage: false,
  returnPage: 'https://boapp-ui.qa.pos.firstclose.com/',
  features: {
    fullStory: {
      isHidden: false,
      title: 'Service Consent',
      body: 'We use FullStory to monitor how consumers interact with our website in order to improve the digital experience. By clicking ‘Accept’, you consent to our use of FullStory in accordance with our privacy policy. For more information, go to <a target="_newtab" href="https://www.loandepot.com/privacypolicy">Privacy Policy | loanDepot</a>.',
      buttonAccept: 'Accept',
      buttonDecline: 'Decline',
    },
  },
  pages: [
    "Let's Get Started",
    "Let's Get Started",
    'Estimated Equity',
    'View My Offer! (Available Programs)',
    'View My Offer!',
    'View My Offer!',
    'Personal Information',
    'Employment Details',
    'Government Info',
    'declarations',
    'Summary',
    'Completion',
  ],
  resume: [
    {
      title: 'Welcome Back! Choose How You’d Like to Continue',
      subTitle: 'You can resume your existing application or start a new one.',
      email: {
        isHidden: true,
        text: '',
      },
      backButton: {
        isHidden: true,
        text: '',
      },
      code: {
        isHidden: true,
        text: '',
      },
      buttonRestart: {
        isHidden: true,
        text: '',
      },
      listLoanSelection: {
        isHidden: true,
        loans: [],
      },
    },
    {
      title: 'Enter Your Email Address',
      subTitle: 'To access your application, please enter the email you used originally.',
      email: {
        isHidden: false,
        text: 'Email Address',
      },
      backButton: {
        isHidden: false,
        text: 'Back',
      },
      code: {
        isHidden: true,
        text: '',
      },
      buttonRestart: {
        isHidden: true,
        text: '',
      },
      listLoanSelection: {
        isHidden: true,
        loans: [],
      },
    },
    {
      title: 'Your Applications',
      subTitle: 'Select an application to continue from where you left off.',
      email: {
        isHidden: true,
        text: '',
      },
      backButton: {
        isHidden: false,
        text: 'Back',
      },
      code: {
        isHidden: true,
        text: '',
      },
      buttonRestart: {
        isHidden: true,
        text: '',
      },
      unableSendEmail: {
        title: 'Unable to Send Email',
        description: 'We were unable to send the verification code to your email.  Start a new application or check your email address and try again.',
        labelButtonLeft: 'Start New Application',
        labelButtonRight: 'Try Another Email',
      },
    },
  ],
  socialLinks: [
    {
      title: 'facebook',
      url: 'https://www.instagram.com/loandepot/',
    },
    {
      title: 'twitter',
      url: 'https://twitter.com/loanDepot',
    },
    {
      title: 'linkedin',
      url: 'https://www.linkedin.com/company/loandepot/',
    },
  ],
  slider: {
    score: [
      {
        label: 'Poor',
        rank: '300-619',
        minRank: 300,
        maxRank: 619,
      },
      {
        label: 'Fair',
        rank: '620-679',
        minRank: 620,
        maxRank: 679,
      },
      {
        label: 'Good',
        rank: '680-719',
        minRank: 680,
        maxRank: 719,
      },
      {
        label: 'Excellent',
        rank: '720+',
        minRank: 720,
        maxRank: 850,
      },
    ],
  },
  navBar: {
    connectSupport: {
      text: 'connect to support',
      isHidden: false,
    },
    phoneHelp: {
      text: 'We are here to help:',
      isHidden: false,
    },
    loanNumber: {
      text: 'Loan Number: ',
      isHidden: false,
    },
  },
  LetsGetStarted: {
    skipRequest: true,
    pageContainer: {
      title: {
        title: 'We’ll help you reach your goal!',
        isHidden: false,
      },
      subtitle: {
        title: 'From applying to closing, we’ll help you every step of the way.',
        isHidden: false,
      },
    },
    consumerInformation: {
      firstName: {
        text: 'First Name',
        isHidden: false,
      },
      middleName: {
        text: 'Middle Initial',
        isHidden: false,
      },
      lastName: {
        text: 'Last Name',
        isHidden: false,
      },
      suffix: {
        title: 'Suffix',
        isHidden: true,
        options: [
          {
            label: 'I',
            beValue: 'I',
          },
          {
            label: 'II',
            beValue: 'II',
          },
          {
            label: 'III',
            beValue: 'III',
          },
          {
            label: 'IV',
            beValue: 'IV',
          },
          {
            label: 'JR',
            beValue: 'JR',
          },
          {
            label: 'SR',
            beValue: 'SR',
          },
          {
            label: 'V',
            beValue: 'V',
          },
          {
            label: 'VI',
            beValue: 'VI',
          },
          {
            label: 'VII',
            beValue: 'VII',
          },
          {
            label: 'VIII',
            beValue: 'VIII',
          },
        ],
      },
      email: {
        text: 'Email Address',
        isHidden: false,
      },
      phone: {
        titleInput: '(000) 000-00000',
        titleDropDown: 'Select',
        isHidden: true,
        options: [
          {
            label: 'Mobil Phone',
            beValue: 'Mobil Phone',
          },
          {
            label: 'Work Phone',
            beValue: 'Work Phone',
          },
          {
            label: 'Home Phone',
            beValue: 'Home Phone',
          },
        ],
      },
      purposeOfLoan: {
        text: 'Purpose of loan',
        isHidden: false,
        options: [
          {
            label: 'Debt Consolidation',
            beValue: 'DEBT CONSOLIDATION',
          },
          {
            label: 'Home Improvement',
            beValue: 'HOME IMPROVEMENT',
          },
          {
            label: 'Personal',
            beValue: 'PERSONAL',
          },
          {
            label: 'Other',
            beValue: 'OTHER',
          },
        ],
      },
      addressSearch: {
        text: 'Search for property address',
        isHidden: false,
      },
      suit: {
        text: 'Unit/Suite',
        isHidden: false,
      },
      unitType: {
        text: 'Unit Type',
        options: [
          {
            label: 'Apartment',
            beValue: 'Apartment',
          },
          {
            label: 'Basement',
            beValue: 'Basement',
          },
          {
            label: 'Suite',
            beValue: 'Suite',
          },
          {
            label: 'Unit',
            beValue: 'Unit',
          },
        ],
        isHidden: false,
      },
      city: {
        text: 'City',
        isHidden: false,
      },
      state: {
        text: 'State',
        isHidden: false,
        options: [
          {
            label: 'AL',
            beValue: 'AL',
          },
          {
            label: 'AK',
            beValue: 'AK',
          },
          {
            label: 'AZ',
            beValue: 'AZ',
          },
          {
            label: 'AR',
            beValue: 'AR',
          },
          {
            label: 'CA',
            beValue: 'CA',
          },
          {
            label: 'CO',
            beValue: 'CO',
          },
          {
            label: 'CT',
            beValue: 'CT',
          },
          {
            label: 'DE',
            beValue: 'DE',
          },
          {
            label: 'FL',
            beValue: 'FL',
          },
          {
            label: 'GA',
            beValue: 'GA',
          },
          {
            label: 'HI',
            beValue: 'HI',
          },
          {
            label: 'ID',
            beValue: 'ID',
          },
          {
            label: 'IL',
            beValue: 'IL',
          },
          {
            label: 'IN',
            beValue: 'IN',
          },
          {
            label: 'IA',
            beValue: 'IA',
          },
          {
            label: 'KS',
            beValue: 'KS',
          },
          {
            label: 'KY',
            beValue: 'KY',
          },
          {
            label: 'LA',
            beValue: 'LA',
          },
          {
            label: 'ME',
            beValue: 'ME',
          },
          {
            label: 'MD',
            beValue: 'MD',
          },
          {
            label: 'MA',
            beValue: 'MA',
          },
          {
            label: 'MI',
            beValue: 'MI',
          },
          {
            label: 'MN',
            beValue: 'MN',
          },
          {
            label: 'MS',
            beValue: 'MS',
          },
          {
            label: 'MO',
            beValue: 'MO',
          },
          {
            label: 'MT',
            beValue: 'MT',
          },
          {
            label: 'NE',
            beValue: 'NE',
          },
          {
            label: 'NV',
            beValue: 'NV',
          },
          {
            label: 'NH',
            beValue: 'NH',
          },
          {
            label: 'NJ',
            beValue: 'NJ',
          },
          {
            label: 'NM',
            beValue: 'NM',
          },
          {
            label: 'NY',
            beValue: 'NY',
          },
          {
            label: 'NC',
            beValue: 'NC',
          },
          {
            label: 'ND',
            beValue: 'ND',
          },
          {
            label: 'OH',
            beValue: 'OH',
          },
          {
            label: 'OK',
            beValue: 'OK',
          },
          {
            label: 'OR',
            beValue: 'OR',
          },
          {
            label: 'PA',
            beValue: 'PA',
          },
          {
            label: 'RI',
            beValue: 'RI',
          },
          {
            label: 'SC',
            beValue: 'SC',
          },
          {
            label: 'SD',
            beValue: 'SD',
          },
          {
            label: 'TN',
            beValue: 'TN',
          },
          {
            label: 'TX',
            beValue: 'TX',
          },
          {
            label: 'UT',
            beValue: 'UT',
          },
          {
            label: 'VT',
            beValue: 'VT',
          },
          {
            label: 'VA',
            beValue: 'VA',
          },
          {
            label: 'WA',
            beValue: 'WA',
          },
          {
            label: 'WV',
            beValue: 'WV',
          },
          {
            label: 'WI',
            beValue: 'WI',
          },
          {
            label: 'WY',
            beValue: 'WY',
          },
          {
            label: 'DC',
            beValue: 'DC',
          },
          {
            label: 'AS',
            beValue: 'AS',
          },
          {
            label: 'GU',
            beValue: 'GU',
          },
          {
            label: 'MP',
            beValue: 'MP',
          },
          {
            label: 'PR',
            beValue: 'PR',
          },
          {
            label: 'VI',
            beValue: 'VI',
          },
        ],
      },
      zip: {
        text: 'Zip Code',
        isHidden: false,
      },
      radioButton: {
        propertyUse: [
          {
            text: 'Primary Residence',
            beValue: 'PRIMARY RESIDENCE',
            isHidden: false,
            width: '10%',
          },
          {
            text: 'Secondary Residence',
            beValue: 'SECOND HOME',
            isHidden: false,
            width: '10%',
          },
        ],
        propertyType: [
          {
            text: 'Single-Family',
            beValue: 'SFR',
            isHidden: false,
            width: '10%',
          },
          {
            text: 'Townhouse',
            beValue: 'TOWNHOUSE',
            isHidden: false,
            width: '10%',
          },
          {
            text: 'Condo',
            beValue: 'LOW RISE CONDO',
            isHidden: false,
            width: '10%',
          },
          {
            text: 'Multi-Family',
            beValue: '2 UNIT',
            isHidden: false,
            width: '10%',
          },
        ],
      },
      purchaseDate: {
        text: 'MM/YYYY',
        isHidden: false,
      },
    },
    protectInformation: {
      text: '<a href="#">Learn how we protect your information.</a>',
      isHidden: false,
    },
    propertyMailingAddress: {
      checkText: 'This property is also my mailing address.',
      information: 'Please provide your mailing address',
      isHidden: false,
    },
    typeOfInformation: {
      consumerInformation: {
        text: 'Consumer Information',
        isHidden: false,
      },
      PropertyInformation: {
        text: 'Property Information',
        isHidden: false,
      },
      consent: {
        text: 'Consent',
        isHidden: false,
      },
    },
    question: {
      basicInformation: {
        text: 'Let’s get some basic information to get started.',
        isHidden: false,
      },
      addressProperty: {
        text: 'What is the address of your property?',
        isHidden: false,
      },
      useOfProperty: {
        text: 'What’s the use of this property?',
        isHidden: false,
      },
      propertyType: {
        text: 'What’s your property type?',
        isHidden: false,
      },
      purchaseDate: {
        text: 'What was the purchase date of this property?',
        isHidden: false,
      },
    },
    softPull: {
      text: 'I agree to allow the Lender to conduct a softpull on my credit.',
      isHidden: false,
    },
    eConsent: {
      text: 'I agree to provide eConsent allowing the Lender to contact me electronically about my loan inquiry.',
      isHidden: false,
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: true,
        text: 'Back',
      },
    },
    modals: {
      resumeInquiry: {
        title: 'Existing Loan Inquiry Found',
        subtitle: "We found an existing loan inquiry associated with this email. To continue, enter the 6-digit code sent to your email and click 'Resume My Inquiry.",
        expiredCode: 'This code expires after one-time use.',
        incorrectCode: 'Incorrect code. Please check and try again.',
        body: "To start a new inquiry, click 'Restart My Loan Inquiry' below. (Creating a new inquiry will archive the existing one.",
        resumeButton: 'Resume My Inquiry',
        restartButton: 'Restart My Inquiry',
      },
      infoSoftPull: {
        header: 'Soft Credit Pull',
        body: 'By authorizing FirstClose to obtain your credit via soft inquiry, in compliance with the Fair Credit Reporting Act, you are allowing FirstClose to obtain such information solely for a pre-qualification for credit. This soft credit inquiry will NOT affect your credit score.',
        button: 'Close',
      },
      protectInformation: {
        header: 'How we protect your data...',
        body: 'We only request and share the information that is required to provide you with your Home Equity Report and a loan pre-qualification determination. All information is encrypted and secure. We not only follow applicable laws, but also industry best practices that go above and beyond whats required to ensure we are at the forefront of the industry in protecting our customers. We adhere to the following privacy principles: We collect and use customers information only as necessary to conduct business. We take steps to prevent identity theft and guard against unauthorized transactions, including engaging qualified industry leading third-party service providers to assist with security services.We only do business with third-parties when we have confirmed such third-party has the appropriate policies, procedures, and systems in place to meet our rigorous security and privacy standards.',
        button: 'Close',
      },
      loadingModal: {
        textOne: 'Your request is being processed.',
        textTwo: 'Please avoid refreshing or closing your browser. ',
      },
      infoSubmitModal: {
        header: 'Soft Credit Pull',
        body: 'By authorizing FirstClose to obtain your credit via soft inquiry, in compliance with the Fair Credit Reporting Act, you are allowing FirstClose to obtain such information solely for a pre-qualification for credit. This soft credit inquiry will NOT affect your credit score. ',
        label: 'Please provide your date birth',
        button: 'Submit',
      },
      exampleModal: {
        header: 'Custom Title',
        body: 'APR 411. In legere consequuntur est, affert tacimates vim id. Mea ei fierent euripidis, id mei tale voluptaria definitiones. Ad case iusto saperet vel, eu assum convenire ius. Cu vero graeco interesset vis, nonumy electram eu sed. Velit pertinax incorrupte ad eam. Prodesset persecuti cotidieque eos ut, ex nec feugiat volutpat percipitur.',
        button: 'Close',
      },
      infoEconsent: {
        type: 'confirm',
        header: 'eConsent Document',
        acceptButton: 'Read Econsent',
        button: 'Close',
        body: 'This document contains information about electronic communications. To read the full eConsent document please click the button below.',
        link: 'https://docs.google.com/gview?embedded=true&url=https://start.loandepot.com/HELOC/forms/loandepot_heloc_econsent.pdf',
      },
      creditScoreNeeded: {
        isHidden: false,
        title: 'Credit Score Needed',
        body: 'We were unable to obtain credit with the information provided. <b>Please select the best estimate of your credit score below to continue.',
        button: 'Submit',
      },
    },
  },
  LetsGetStartedAddress: {
    placeHolder: {
      button: 'Check My Equity',
      isHidden: false,
    },
    PropertyCard: {
      title: 'My Property',
      address: 'Address',
      suit: 'Unit/Suite',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      country: 'Country',
      isHidden: false,
    },
    map: {
      isHidden: false,
    },
    modals: {
      editAddress: {
        header: 'Oops! Not the right property?',
        subtitle: 'Provide your correct address below',
        button: 'Update Address',
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  EstimatedAvailableEquity: {
    placeHolder: {
      button: 'View My Offer',
    },
    EquityCalculator: {
      estimatedMarket: {
        title: 'Estimated Market Value',
        text: 'The Estimated Market Value is based on and automated valuation model (AVM) used by many lenders and real estate platforms to initiate home equity valuations.',
        isHidden: false,
      },
      configureMessage: {
        text: 'We were unable to retrieve the estimated market value and remaining mortgage balance for this property. You can manually enter the estimated market value and remaining mortgage balance, then click "confirm" to continue with your loan inquiry.',
        button: 'Confirm',
        isHidden: false,
      },
      remainingMortgage: {
        title: 'Remaining Mortgage Balance',
        text: 'The Remaining Mortgage Balance is an estimate of what you owe based on public information. You can adjust the amount remaining here, if the publicly available information is incorrect.',
        isHidden: false,
      },
      estimatedHome: {
        title: 'Estimated Home Equity',
        text: 'Your Estimated Home Equity is the total amount of equity in your home.',
        isHidden: false,
      },
      availableEquity: {
        title: 'Available Equity To Borrow',
        text: 'Your available equity to borrow is an estimate based on program loan-to-value (LTV) criteria; keep in mind you may be eligible for more or less depending on program LTV limits.',
        isHidden: false,
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  vmo: {
    programs: [
      {
        id: 1,
        title: 'Line of Credit',
        description:
          'A home equity line of credit (HELOC) is a line of credit secured by your home that gives you a revolving credit line to use for large expenses or to consolidate higher-interest rate debt on other loans such as credit cards.',
        isHidden: false,
      },
      {
        id: 2,
        title: 'Home Equity Loan',
        description:
          'A home equity line of credit (HEL) is a line of credit secured by your home that gives you a revolving credit line to use for large expenses or to consolidate higher-interest rate debt on other loans such as credit cards.',
        isHidden: false,
      },
    ],
    titleOneProgram: {
      text: 'Congratulations, you’re prequalified for a credit line up to %%maxLineAmount%%!',
      isHidden: false,
    },
    titleMultipleProgram: {
      text: 'It looks like you’re a good fit for multiple loan programs!',
      isHidden: false,
    },
    title: {
      text: 'Congratulations, you’re prequalified for a credit line up to %%maxLineAmount%%!',
      isHidden: false,
    },
    subtitle: {
      text: 'Compare your options by clicking between Line of Credit and Home Equity Loan and select the path that best suits your needs',
      isHidden: false,
    },
    ProgramSelectorTitle: {
      text: 'Select a program that works for you.',
      isHidden: false,
    },
    verbiage: {
      text: 'Learn more about this loan program',
      isHidden: false,
    },
    placeHolder: {
      button: 'Next',
      isHidden: false,
    },
    titleOfProgram: {
      isHidden: false,
    },
    typeOFProgram: {
      isHidden: false,
    },
    lineAmount: {
      title: 'LINE AMOUNT',
      isHiddenHeloc: false,
      isHiddenHel: true,
      infoIcon: {
        bodyLow: 'This is the Line Amount Info Low Amount',
        bodyHigh: 'This is the Line Amount Info High Amount',
        button: 'Close',
      },
    },
    loanAmount: {
      title: 'LOAN AMOUNT',
      isHiddenHeloc: true,
      isHiddenHel: false,
      infoIcon: {
        bodyLow: 'This is the Loan Amount Info Low Amount',
        bodyHigh: 'This is the Loan Amount Info High Amount',
        button: 'Close',
      },
    },
    estimatedApr: {
      title: 'ESTIMATED APR',
      isHiddenHeloc: false,
      isHiddenHel: false,
      infoIcon: {
        bodyLow: 'This is the Estimated APR Info Low Amount',
        bodyHigh: 'This is the Estimated APR Info High Amount',
        button: 'Close',
      },
    },
    estimateMonthlyPmt: {
      title: 'EST MONTHLY PMT',
      isHiddenHeloc: true,
      isHiddenHel: false,
      infoIcon: {
        bodyLow: 'This is the Estimated Monthly PMT Info Low Amount',
        bodyHigh: 'This is the Estimated Monthly PMT Info High Amount',
        button: 'Close',
      },
    },
    originationFee: {
      title: 'ORIGINATION FEE',
      isHiddenHeloc: false,
      isHiddenHel: true,
      infoIcon: {
        bodyLow: 'This is the Origination Fee Info Low Amount',
        bodyHigh: 'This is the Origination Feet Info High Amount',
        button: 'Close',
      },
    },
    initialDraw: {
      title: 'INITIAL DRAW',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Initial Draw Amount Info Low Amount',
        bodyHigh: 'This is the Initial Draw Amount Info High Amount',
        button: 'Close',
      },
    },
    minimumDraw: {
      title: 'MINIMUM DRAW',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Minimum Draw Amount Info Low Amount',
        bodyHigh: 'This is the Minimum Draw Amount Info High Amount',
        button: 'Close',
      },
    },
    maximumDraw: {
      title: 'MAXIMUM DRAW',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Maximum Draw Amount Info Low Amount',
        bodyHigh: 'This is the Maximum Draw Amount Info High Amount',
        button: 'Close',
      },
    },
    footerMaxAmount: {
      isHidden: false,
    },
    footer: {
      text: 'The variable minimum monthly payment during the draw period will be sufficient to pay the sum of all finance charges and other charges accrued each month; and during the repayment phase, an amount sufficient to repay the outstanding principle balance, interest and fees over the remaining term at the applicable APR. Minimum payment is $100.00',
      isHidden: false,
    },
    minLineAmount: 'MIN LINE AMOUNT',
    maxLineAmount: 'MAX LINE AMOUNT',
    minLoanAmount: 'MIN LOAN AMOUNT',
    maxLoanAmount: 'MAX LOAN AMOUNT',
    terms: {
      title: {
        text: 'Congratulations, you’re prequalified for a credit line up to %%maxLineAmount%%!',
        isHidden: false,
      },
      cardAmount: {
        title: {
          text: 'Your Selected Line Amount and Terms',
          isHidden: false,
        },
        infoSection: [
          {
            lineAmount: {
              title: 'LINE AMOUNT',
              isHiddenHeloc: false,
              isHiddenHel: true,
            },
            loanAmount: {
              title: 'LOAN AMOUNT',
              isHiddenHeloc: true,
              isHiddenHel: false,
            },
            apr: {
              title: 'ESTIMATED APR',
              isHiddenHeloc: false,
              isHiddenHel: false,
            },
            estimateMonthlyPmt: {
              title: 'EST MONTHLY PMT',
              isHiddenHeloc: true,
              isHiddenHel: false,
            },
            loanOriginationFee: {
              title: 'ORIGINATION FEE',
              isHiddenHeloc: true,
              isHiddenHel: true,
            },
          },
        ],
      },
    },
    vmoCalculate: {
      title: {
        text: 'How much of your line amount would you like to draw at closing?',
        isHidden: false,
      },
      vmoLineAmount: {
        titleContainer: {
          text: 'Calculate Your Line Amount Terms',
          isHidden: false,
        },
        title: {
          text: 'Enter your desired <b>Line amount</b> and click the reprice button to see estimated terms.',
          isHidden: false,
        },
        subtitle: {
          text: 'The line amount requested is subject to credit approval and may be reduced',
          isHidden: false,
        },
        amountTerms: [
          {
            lineAmount: {
              title: 'LINE AMOUNT',
              titleLoan: 'LOAN AMOUNT',
              isHidden: false,
            },
            apr: {
              title: 'EST MONTHLY PMT',
              isHidden: false,
            },
            originationFee: {
              title: 'ESTIMATED APR',
              isHidden: false,
            },
          },
        ],
        rangeAmount: {
          text: 'Enter an amount between %%minLineAmount%% and %%maxLineAmount%%',
          isHidden: false,
        },
        footer: {
          text: 'This is HELOC description',
          isHidden: false,
        },
        pageOrigin: 'VMO_LINE_AMOUNT',
        pageDestination: 'VMO_DRAW_AMOUNT',
      },
      vmoLoanAmount: {
        titleContainer: {
          text: 'Calculate Your Loan Amount Terms',
          isHidden: false,
        },
        title: {
          text: 'Enter your desired <b>Loan amount</b> and click the reprice button to see estimated terms.',
          isHidden: false,
        },
        subtitle: {
          text: 'The Loan amount requested is subject to credit approval and may be reduced',
          isHidden: false,
        },
        amountTerms: [
          {
            lineAmount: {
              title: 'LOAN AMOUNT',
              isHidden: false,
            },
            apr: {
              title: 'EST MONTHLY PMT',
              isHidden: false,
            },
            originationFee: {
              title: 'ESTIMATED APR',
              isHidden: false,
            },
          },
        ],
        rangeAmount: {
          text: 'Enter an amount between %%minLineAmount%% and %%maxLineAmount%%',
          isHidden: false,
        },
        footer: {
          text: 'The variable minimum monthly payment during the draw period will be sufficient to pay the sum of all finance charges and other charges accrued each month; and during the repayment phase, an amount sufficient to repay the outstanding principle balance, interest and fees over the remaining term at the applicable APR. Minimum payment is $100.00',
          isHidden: false,
        },
      },
      vmoDrawAmount: {
        titleContainer: {
          text: 'Initial Draw Amount at Closing',
          isHidden: false,
        },
        title: {
          text: 'Enter your desired <b>Initial Draw amount</b> (funds disbursed to you immediately at closing.)',
          isHidden: false,
        },
        Subtitle: {
          text: 'Enter your desired <b>Initial Draw amount</b> (funds disbursed to you immediately at closing.)',
          isHidden: false,
        },
        amountTerms: [
          {
            lineAmount: {
              title: 'INITIAL DRAW',
              isHidden: false,
            },
            minimumDraw: {
              title: 'MINIMUM DRAW',
              isHidden: false,
            },
            originationFee: {
              title: 'MAXIMUM DRAW',
              isHidden: false,
            },
          },
        ],
        rangeAmount: {
          text: 'Enter an amount between %%minLineAmount%% and %%maxLineAmount%%',
          isHidden: false,
        },
        footer: {
          text: 'The variable minimum monthly payment during the draw period will be sufficient to pay the sum of all finance charges and other charges accrued each month; and during the repayment phase, an amount sufficient to repay the outstanding principle balance, interest and fees over the remaining term at the applicable APR. Minimum payment is $100.00',
          isHidden: false,
        },
        pageOrigin: 'VMO_DRAW_AMOUNT',
        pageDestination: 'PERSONAL_INFORMATION',
      },
      button: 'Reprice',
    },
    vmoV2: {
      modal: {
        infoDebConsolidation: {
          header: 'You can consolidate high interest loans with a First Close HELOC!',
          body: 'Would you like to explore Debt Consolidation?',
          button: 'No',
          acceptButton: 'Yes, Let´s do it!',
        },
        tableAmountReached: {
          header: 'Total Line Amount Reached',
          body: 'Your included accounts exceed your stated line amount. Please increase your line amount to include more accounts.',
          button: 'Close',
        },
      },
      active: true,
      titleProgramSection: {
        text: 'Enter your desired line amount to compare and choose between your offers.',
        isHidden: false,
      },
      subtitleProgramSection: {
        text: 'Pick the HELOC that’s right for you.',
        isHidden: false,
      },
      calculateAmount: {
        title: {
          text: 'Enter your Line and Draw Amount',
          isHidden: false,
        },
        sectionLineAmount: {
          titleLoan: 'Loan Amount',
          title: 'Line Amount',
          isHidden: false,
          infoIcon: {
            bodyLow: 'This is the Line Amount Info Low Amount',
            bodyHigh: 'This is the Line Amount Info High Amount',
            button: 'Close',
          },
        },
        sectionDrawAmount: {
          title: 'Draw Amount',
          isHidden: false,
          infoIcon: {
            bodyLow: 'PROGRAMS This is the Draw Amount Info Low Amount',
            bodyHigh: 'PROGRAMS This is the Draw Amount Info High Amount',
            button: 'Close',
          },
        },
        button: 'Reprice',
      },
      programCard: {
        estimatedAPR: {
          labelEstimatedAPR: 'Estimated',
          subLabelEstimatedApr: 'APR',
          isHidden: false,
          isInfoIconEstimatedApr: true,
          infoIcon: {
            bodyLow: 'This is the Estimated APR Info Low Amount',
            bodyHigh: 'This is the Estimated APR Info High Amount',
            button: 'Close',
          },
        },
        interestOnlyPayment: {
          labelEstimatedMonth: 'Estimated',
          subBLabelEstimatedMonth: 'Interest Only Payment',
          isHidden: false,
          isInfoIconEstimatedMonth: true,
          infoIcon: {
            bodyLow: 'This is the Interest Only Payment Info Low Amount',
            bodyHigh: 'This is the Interest Only Payment Info High Amount',
            button: 'Close',
          },
        },
        originationFee: {
          labelOriginalFee: '',
          subLabelOriginalFee: 'Origination Fee',
          isHidden: false,
          isInfoIconOriginalFee: true,
          infoIcon: {
            bodyLow: 'This is the Origination Fee Info Low Amount',
            bodyHigh: 'This is the Origination Fee Info High Amount',
            button: 'Close',
          },
        },
        interestOnlyPeriod: {
          labelInterest: '',
          subLabelInterest: 'Interest Only Period',
          isHidden: false,
          isInfoIconInterest: false,
          infoIcon: {
            bodyLow: 'This is the Interest Only Period Info Low Amount',
            bodyHigh: 'This is the Interest Only Period Info High Amount',
            button: 'Close',
          },
        },
        APR: {
          title: 'APR ',
          textOneAPR: 'Estimated ',
          APRHidden: false,
          infoIcon: {
            bodyLow: 'This is the Estimated APR Info Low Amount',
            bodyHigh: 'This is the Estimated APR Info High Amount',
            button: 'Close',
          },
        },
        drawPeriod: {
          title: 'Draw Period',
          isHidden: false,
          textOneDrawPeriod: 'Year',
        },
        Repayment: {
          title: 'Repayment',
          isHidden: false,
          textOneRepayment: 'Year',
        },
      },
      debtConsolidation: {
        title: {
          text: 'Discover the Triple Advantage',
          isHidden: false,
        },
        subtitle: {
          text: 'Debt Consolidation, Lower Monthly Payment, and Cash in Hand with a loanDepot HELOC',
          isHidden: false,
        },
        tableQuestions: {
          text: 'Questions? Call (866)790-3940 to be connected to a Digital Loan Consultant between 5am-6pm PT. After hours? No problem, call and leave a message. A return call will be made by 9AM PT the following business day.',
          isHidden: false,
        },
        wrapperAmount: {
          isHidden: false,
          lineAmount: {
            text: 'Line Amount Used',
            isHidden: false,
          },
          totalUsed: {
            text: 'Total used',
            isHidden: false,
          },
          cashBack: {
            text: 'Cash back to you',
            isHidden: false,
          },
          totalDraw: {
            text: 'Total draw amount',
            isHidden: false,
          },
        },
        potentialMonthly: {
          title: 'Potential Monthly Payment Savings',
          superScript: '(4)',
          isHidden: false,
          chart: {
            isHidden: false,
          },
          yourLoan: {
            title: 'Your loanDepot HELOC',
            subtitle: 'Estimated I/O payment',
            isHidden: false,
          },
          otherDebts: {
            title: 'Other Debts',
            subtitle: 'Monthly payment',
            isHidden: false,
          },
          potentialMonthly: {
            title: 'Potential Savings',
            subtitle: 'Potential monthly savings',
            isHidden: false,
          },
        },
      },
      tableProgram: {
        isHidden: false,
        head: {
          include: 'Include',
          account: 'Account',
          balance: 'Balance',
          monthlyPayment: 'Monthly Payment',
          remove: 'Not Yours or Old? <br>Remove From Calculation',
        },
      },
      creditReported: {
        isHidden: false,
        title: 'Credit Information Reported',
        verbiage:
          'Based on information reported on your credit report. Your actual account balance and minimum monthly payment may differ. Note that you may be required to payoff accounts directly with the creditor. Any existing subordinate lien will need to be paid off as part of your HELOC transaction.',
      },
      potentialEstimate: {
        isHidden: false,
        title: 'Potential Savings Estimate',
        verbiage:
          'Potential savings are estimates only and are based on your initial estimated monthly interest only period payment and the information reported on your credit report. Monthly savings are calculated by subtracting the minimum payment amount for debts you select to pay off from your estimated initial HELOC payment.​ Monthly savings do not account for the interest rate on your existing debts, which may be higher or lower than the interest rate that will apply to your loanDepot HELOC. Additionally, loanDepot’s HELOC has a variable rate. Because your interest rate may change and your account balance may increase or decrease as you take additional draws or repay the balance, the monthly minimum payment due will also change. Further, your payment period payment will include principal, in addition to interest, which may cause your minimum payment to increase. Except for your final payment, your minimum monthly payment will always be at least $100.',
      },
      footer: [
        'Available funds are accessible via ACH/Wire only.',
        'The amount of the initial draw deposited to your account will be reduced by the amount of the origination fee.',
        'The monthly payment is an estimate of your initial variable rate interest only monthly payment and is based on the line amount shown. The estimated payments assume that the applicable interest rate and account balances as of the draw period and repayment period are the same as the line amount listed above. The estimated payment amount adjusts as you change the line amount and hit the reprice button.',
        'Potential monthly savings are estimates and calculated by subtracting the minimum payment amounts for the debts you select to pay off from your estimated initial interest only HELOC payment.',
        'Rates and terms may vary based on requested line amount, credit history and available equity.',
        '<b>Your HELOC is a variable rate line of credit.</b> Because your interest rate and account balance may change over the life of your HELOC, the minimum payment due may also change. During the repayment period, your monthly payment will include principal, in addition to interest and other charges. Estimated payments are based on a 31-day billing cycle. Billing cycles may vary and can impact the actual payment due.',
        'For additional information about how your minimum payments will be calculated and for other important details, please see your Important Terms of Our Home Equity Line of Credit document provided with your initial disclosures.',
      ],
      NavigationButton: {
        nextButton: 'Next',
        backButton: {
          isHidden: false,
          text: 'Back',
        },
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
    vmoDebtConsolidation: {
      debtConsolidation: {
        isActive: true,
        selectProgramText: 'Select a program to explore how consolidating your debts can lower your monthly bill',
        selectProgramTextLd: 'Select a program to explore: Debt Consolidation, Lower Monthly Payment, and Cash in hand with HELOC.',
        titleLd: 'Discover the Triple Advantage',
        title: 'Explore how consolidating your debts can lower your monthly bill.',
        body: 'Debt Consolidation, Lower Monthly Payment, and Cash in hand with HELOC.',
        debtConsolidationTable: {
          include: 'Include',
          exclude: 'Not Yours or Old? <br> Remove From Calculation',
        },
      },
      contactUsMessage:
        'Questions? Call (866)790-3940 to be connected to a Digital Loan Consultant between 5am-6pm PT. After hours? No problem, call and leave a message. A return call will be made by 9AM PT the following business day.',
      debtConsolidationReport: {
        percentageUsed: 'Line Amount Used',
        totalDebtUsed: 'Total Used',
        cashBack: 'Cash back to you',
        totalAmount: {
          totalDraw: 'Total draw amount',
          totalLine: 'Total line amount',
        },
      },
      potentialMonthlySavingReport: {
        title: 'Potential Monthly Payment Savings',
        monthlyLoanPercentageLd: 'Your FirstClose HELOC ',
        monthlyLoanPercentage: 'Your loan HELOC',
        monthlyLoanAmount: 'Estimated I/O payment',
        otherDebtsPrecentage: 'Other Debts',
        otherDebtAmount: 'Monthly payment',
        potentialSavingPercentage: 'Potential Savings',
        potentialSavingAmount: 'Potential monthly savings',
      },
      creditInformationReportModal: {
        isActive: true,
        isExpand: true,
        title: 'Credit Information Reported',
        value:
          'Based on information reported on your credit report. Your actual account balance and minimum monthly payment may differ. Note that you may be required to payoff amounts directly with the creditor. Any existing subordinate lien will need to be paid off as part of your HELOC transaction.',
      },
      potentialSavingEstimateModal: {
        isActive: true,
        isExpand: true,
        title: 'Potential Savings Estimate',
        value:
          'Potential savings are estimates only and are based on your initial estimated monthly interest only period payment and the information reported on your credit report. Monthly savings are calculated by subtracting the minimum payment amount for debts you select to pay off from your estimated initial HELOC payment. Monthly savings do not account for the interest rate on your existing debts, which may be higher or lower than the interest rate that will apply to your FirstClose HELOC. Additionally, <b>FirstClose’s HELOC has a variable rate.</b> Because your interest rate may change and your account balance may increase or decrease as you take additional draws or repay the balance, the monthly minimum payment due will also change. Further, your payment period payment will include principal, in addition to interest, which may cause your minimum payment to increase. Except for your final payment, your minimum monthly payment will always be at least $100.',
      },
      footnoteSection: {
        alignment: 'center',
        footerOne:
          '<b> Rate and Terms are not guaranteed</b> and may change before account opening without notice.  Additionally, credit and collateral approval is required.  Your final initial rate will be set at the time the application is fully underwritten.  To check the rates and terms for which you may qualify, we will conduct a soft credit pull that will not impact your credit score.  If you continue and submit an inquiry, however, we will request your full credit report from one or more consumer reporting agencies, which is considered a hard credit pull and may impact your credit score.',
        superScripts: [
          {
            superScripts: '(1)',
            value: ' Available funds are accessible via ACH/Wire only.',
          },
          {
            superScripts: '<br />(2)',
            value: ' The amount of the initial draw deposited to your account will be reduced by the amount of the origination fee.',
          },
          {
            superScripts: '<br />(3)',
            value:
              ' He monthly payment is an estimate of your initial variable rate interest only monthly payment and is based on the line amount shown. The estimated payments assume that the applicable interest rate and account balances as of the draw period and repayment period are the same as the line amount listed above. The estimated payment amount adjusts as you change the line amount and hit the reprice button.',
          },
          {
            superScripts: '<br />(4)',
            value:
              ' Potential monthly savings are estimates and calculated by subtracting the minimum payment amounts for the debts you select to pay off from your estimated initial interest only HELOC payment.',
          },
          {
            superScripts: '<br />(5)',
            value: ' Rates and terms may vary based on requested line amount. credit history and available equity.',
          },
          {
            superScripts: '<br />(6)',
            value:
              ' <b>Your HELOC is a variable rate line of credit.</b> Because your interest rate and account balance may change over the life of your HELOC, the minimum payment due may also change. During the repayment period, your monthly payment will include principal, in addition  to interest and other charges. Estimated payments are based on a 31-day billing cycle. Billing cycles may vary and can impact the actualpayment due',
          },
          {
            superScripts: '<br />(7)',
            value:
              ' For additional information about how your minimum payments will be calculated and for other important details, please see your Important Terms of Our Home Equity Line of Credit document provided with your initial disclosures.',
          },
        ],
      },
    },
    vmoItems: {
      loanAmount: 'Loan Amount',
      lineAmount: {
        title: 'Line Amount',
        lineAmountHidden: false,
      },
      drawAmount: {
        title: 'Draw Amount',
        drawAmountHidden: false,
        initialDraw: 'INITIAL DRAW',
        minimunDraw: 'MINIMUN DRAW',
        maximunDraw: 'MAXIMUM DRAW',
      },
      estimatedAPR: {
        title: 'ESTIMATED APR',
        estimatedAPRHidden: false,
      },
      estimatedPayment: {
        title: 'EST MONTHLY PMT',
        textOnEstimatedPayment: 'Estimated',
        estimatedPaymentHidden: false,
      },
      originationFee: {
        title: 'Origination Fee',
        titleVmoOld: 'One Time Origination Fee',
        textOneOriginationFee: '',
        originationFeeHidden: false,
      },
      interestOnlyPeriod: {
        title: 'Interest Only Period',
        interestOnlyPeriodHidden: false,
        interestOnlyPeriodTextOne: 'Year',
        interestOnlyPeriodNoLd: 'Payment',
        textOneInterestOnlyPeriodNoLd: 'Monthly',
      },
      interestOnlyPayment: {
        title: 'Interest Only Payment',
        interestOnlyPaymentTextOne: 'Estimated',
        interestOnlyPaymentHidden: false,
      },
      APR: {
        title: 'APR',
        textOneAPR: 'Estimated',
        APRHidden: false,
      },
      drawPeriod: {
        title: 'Draw Period',
        textOneDrawPeriod: 'Year',
      },
      Repayment: {
        title: 'Repayment',
        textOneRepayment: 'Year',
      },
      learnMoreLoanProgram: 'Learn more about this loan program',
      OneLoanProgram: 'Input your desired line and draw amount to see your offers',
      multipleLoanPrograms: 'Congratulations, you&apos;re prequalified for a credit line up to ',
      helecLoanPrograms:
        "<div class='text-top'>Enter your desired line amount to compare and choose between your offers. </br> <span class='heloc-verbiage'>Pick the HELOC that's right for you.</span><div>",
      buttonNewVMO: {
        title: 'Next',
        icon: 'fa-regular fa-circle-arrow-right',
      },
    },
    vmoInfo: {
      lineAmount: {
        lineAmountHigh:
          "Hi You are invited to apply for a Home Equity Line of Credit ('HELOC'). This is not a commitment to lend; you must submit additional information for review and approval.  The minimum line amount available is $%minLineAmount% or as required by state law.  The minimum initial draw amount is %DrawPercentage%% of the requested credit line amount.  The line amount is subject to credit approval and may be reduced.  Rates and terms may vary based on required line amount.",
        lineAmountLow: 'Lo The minimum initial draw amount may change based on line amount ultimately approved.',
      },
      loanAmount:
        'Take advantage of the available equity in your home, up to 90% of your home value. Loan amounts ranging between %minLineOld%k and %maxLineOld%k.<br/>• The application process is similar to a mortgage because your home is used as collateral to secure the loan. It may require a home assessment to determine its value.<br/>• Final approval subject to credit qualifications.',
      estimatedMtPayment: {
        estimatedMtPaymentHigh:
          "FirstClose's HELOC is interest only for the first ten years (or for the first three years in Texas) with a minimum payment of at least $100 per month.  The estimated payment is based on your line amount and initial estimated APR.  Your account balance and interest rate may change over the life of the loan, resulting in a different minimum payment. Click <a href=https://www.firstclose.com/ style= color: #5C2D91; target=_blank><strong>here</strong></a> to calculate your estimated monthly payment using different line amounts.",
        estimatedMtPaymentLow:
          "FirstClose's HELOC is interest only for the first ten years (or for the first three years in Texas) with a minimum payment of at least $100 per month.  The estimated payment is based on your line amount and initial estimated APR.  Your account balance and interest rate may change over the life of the loan, resulting in a different minimum payment. Click <a href=https://www.firstclose.com/ style= color: #5C2D91; target=_blank><strong>here</strong></a> to calculate your estimated monthly payment using different line amounts.",
      },
      estimatedAPR: {
        estimatedAPRHigh:
          'Hi Offers and rates are not guaranteed and may change before account opening without notice. Rates and terms may vary based on requested line amount. Final initial rate will be set at the time application is fully underwritten. The variable APR is based on Wall Street Journal Prime Rate plus a margin and will vary with Prime Rate. Your APR will not exceed 18.00% at any time during the term of your account (could vary based on limits imposed by state law).<br><br> The term of a HELOC is 30 years.  For non-Texas properties, the 30-year term includes: a 3-year draw period (within a 10-year interest only period) and a 20-year repayment period. For Texas properties, the 30-year term includes a 3-year draw and interest only period and a 27-year repayment period.  The variable minimum monthly payment during the draw and interest only periods will be sufficient to pay the sum of all finance charges and other charges accrued each month and during the repayment phase, an amount sufficient to repay the outstanding principal balance, interest, and fees over the remaining term at the applicable APR.</br><br> Minimum payment is at least $100. Property insurance is always required, and flood insurance is required if the property used as collateral is in a special flood hazard area. Certain property types are not eligible collateral for a FirstClose HELOC. The minimum line amount is $%minLineAmount% or the minimum amount required by state law. The maximum line amount is $%maxLineAmount%. There are no third-party fees. A HELOC requires you to pledge your home as collateral and you could lose your home if you fail to repay. We determine home value and resulting equity through independent data sources and automated valuation models. Credit criteria, exclusions, and limitations apply. Excellent credit is required to qualify for the lowest rates. Consult your tax advisor regarding interest deductibility. A 1098 will be issued as required by law.</br>',
        estimatedAPRLow:
          'Lo Offers and rates are not guaranteed and may change before account opening without notice. Rates and terms may vary based on requested line amount. Final initial rate will be set at the time application is fully underwritten. The variable APR is based on Wall Street Journal Prime Rate plus a margin and will vary with Prime Rate. Your APR will not exceed 18.00% at any time during the term of your account (could vary based on limits imposed by state law).<br><br> The term of a HELOC is 30 years.  For non-Texas properties, the 30-year term includes: a 3-year draw period (within a 10-year interest only period) and a 20-year repayment period. For Texas properties, the 30-year term includes a 3-year draw and interest only period and a 27-year repayment period.  The variable minimum monthly payment during the draw and interest only periods will be sufficient to pay the sum of all finance charges and other charges accrued each month and during the repayment phase, an amount sufficient to repay the outstanding principal balance, interest, and fees over the remaining term at the applicable APR.</br><br> Minimum payment is at least $100. Property insurance is always required, and flood insurance is required if the property used as collateral is in a special flood hazard area. Certain property types are not eligible collateral for a FirstClose HELOC. The minimum line amount is $%minLineAmount% or the minimum amount required by state law. The maximum line amount is $%maxLineAmount%. There are no third-party fees. A HELOC requires you to pledge your home as collateral and you could lose your home if you fail to repay. We determine home value and resulting equity through independent data sources and automated valuation models. Credit criteria, exclusions, and limitations apply. Excellent credit is required to qualify for the lowest rates. Consult your tax advisor regarding interest deductibility. A 1098 will be issued as required by law.</br>',
      },
      originationFee:
        'Origination fees are a percentage of the total credit line amount and may vary by state and by credit line amount ultimately approved. After closing, additional charges, including a maintenance fee of up to $99 assessed annually after the first year, may be charged. Please see the Important Terms of Our Home Equity Line of Credit for additional information about fees.',
      drawAmount: {
        initialDraw: {
          lowLineAmount: 'Lo Please note this program requires an initial draw of %DrawPercentage%% of the requested line amount.',
          highLineAmount: 'Hi Please note this program requires an initial draw of %DrawPercentage%% of the requested line amount.',
        },
        minimumDraw: {
          lowLineAmount:
            'Low Please note this program requires an initial draw of %DrawPercentage%% of the requested line amount. The number below is %DrawPercentage%% of the credit line you selected in the prior screen.',
          highLineAmount:
            'Hi Please note this program requires an initial draw of %DrawPercentage%% of the requested line amount. The number below is %DrawPercentage%% of the credit line you selected in the prior screen.',
        },
        maximumDraw: {
          lowLineAmount:
            'Lo Please note this program requires an initial draw of %DrawPercentage%% of the requested line amount.The number below is the amount of the credit line you selected in the prior screen.',
          highLineAmount:
            'Hi Please note this program requires an initial draw of %DrawPercentage%% of the requested line amount.The number below is the amount of the credit line you selected in the prior screen.',
        },
      },
      helocCreditUnionVerbiage: {
        title: 'Credit Union Membership Requirement',
        body: "By selecting this program, you are opting in for a credit union membership. A credit union will be selected from among the list available  <a href='https://www.firstclose.com/' rel='noopener noreferrer' target='_blank' > here </a> FirstClose will still be your HELOC lender. The selected credit union will fund an account in your name and will provide you membership disclosures and account statements, but no further financial commitment will be required to open your credit union account.<br /> <br /> If you opt not to join a credit union, your interest rate may be higher, as shown for non-credit union program offers",
      },
    },
  },
  personalInformation: {
    placeHolder: {
      button: 'Next',
      text: 'borrower',
      isHidden: true,
    },
    messages: {
      ssnMessages: {
        title: 'Why do we ask for your SSN?',
        body: 'loanDepot will obtain a consumer credit report at the time of inquiry and may also do so for updates, renewals, extensions, and collection activity on an approved account. loanDepot will inform you if we obtained a report, and if so, the name and address of the consumer reporting agency that provided it. You agree that loanDepot can obtain any credit, employment and income information about you, can keep this inquiry and supporting information, and if the inquiry is approved, you will abide by the agreement for this account.',
        isHidden: false,
      },
      coBorrowerMessages: {
        title: 'Adding a co-applicant to this loan?',
        body: 'This loan program requires co-applicants to submit the loan inquiry as Joint credit applicants. Joint credit makes it possible to open an account with another borrower where repayment and responsibility are shared. In the case of joint credit, two or more people apply together for approval and become account owners that are equally responsible for the balance.',
        isHidden: false,
      },
    },
    checkConsent: 'By clicking here I agree to the Lenders <a href="#">TCPA disclosure </a>allowing the lending team to contact the me via phone.',
    formInputs: {
      selectStatus: {
        placeHolder: 'Select Status',
        label: 'Marital Status',
        isHidden: false,
        options: [
          {
            label: 'married',
            beValue: 'MARRIED',
          },
          {
            label: 'separated',
            beValue: 'SEPARATED',
          },
          {
            label: 'unmarried',
            beValue: 'UNMARRIED',
          },
        ],
      },
      dateBirth: {
        placeHolder: 'MM/DD/YYYY',
        label: 'Date of Birth',
        isHidden: false,
      },
      SSN: {
        label: 'Social Security Number (SSN)',
        placeHolder: '### - ## - ####',
        isHidden: false,
      },
      homePhone: {
        label: 'Home Phone',
        placeHolder: '(###) ### - ####',
        isHidden: false,
      },
      workPhone: {
        label: 'Work Phone',
        placeHolder: '(###) ### - ####',
        isHidden: false,
      },
      mobilePhone: {
        label: 'Mobile Phone',
        placeHolder: '(###) ### - ####',
        isHidden: false,
      },
      emailAddress: {
        label: 'Email Address',
        placeHolder: 'you@email.com',
        isHidden: true,
      },
      contactMethod: {
        label: 'Preferred Contact Method',
        placeHolder: 'Select Preference',
        isHidden: false,
        options: [
          {
            label: 'email',
            beValue: 'Email',
          },
          {
            label: 'home',
            beValue: 'home',
          },
          {
            label: 'work',
            beValue: 'work',
          },
          {
            label: 'cell',
            beValue: 'cell',
          },
        ],
      },
      citizenship: {
        label: 'Citizenship',
        placeHolder: 'Select Status',
        isHidden: false,
        options: [
          {
            label: 'U.S. Citizen',
            beValue: 'USCITIZEN',
          },
          {
            label: 'Permanent Resident',
            beValue: 'PERMRESIDENT',
          },
          {
            label: 'Non-resident Alien',
            beValue: 'NONRESIDENTALIEN',
          },
        ],
      },
      Coborrower: {
        title: {
          text: 'Co-Borrower',
          isHidden: false,
        },
        coborrowerConsent: {
          text: 'I agree to the Consent to Receive Electronic Loan Documents and understand that communications, loan documents, and other disclosures will be provided to me electronically. Read <a href="https://docs.google.com/gview?embedded=true&amp;url=https://start.loandepot.com/HELOC/forms/loandepot_heloc_econsent.pdf" target="_blank">Read eConsent</a> to receive documents electronically is required for this program',
          isHidden: false,
        },
        text: {
          text: 'Adding a %coApplicantLabelLowercase% to this loan?',
          isHidden: false,
        },
        subtitle: {
          text: 'This loan program requires %coApplicantLabelLowercase%s to submit the loan inquiry as Joint credit applicants. Joint credit makes it possible to open an account with another borrower where repayment and responsibility are shared. In the case of joint credit, two or more people apply together for approval and become account owners that are equally responsible for the balance.',
          isHidden: false,
        },
        firstName: {
          label: 'First Name',
          placeHolder: 'First Name',
          isHidden: false,
        },
        middleName: {
          label: 'Middle Initial',
          placeHolder: 'Middle Initial',
          isHidden: false,
        },
        lastName: {
          label: 'Last Name',
          placeHolder: 'Last Name',
          isHidden: false,
        },
        suffix: {
          label: 'Suffix',
          title: 'Suffix',
          isHidden: false,
          options: [
            {
              label: 'I',
              beValue: 'I',
            },
            {
              label: 'II',
              beValue: 'II',
            },
            {
              label: 'III',
              beValue: 'III',
            },
            {
              label: 'IV',
              beValue: 'IV',
            },
            {
              label: 'JR',
              beValue: 'JR',
            },
            {
              label: 'SR',
              beValue: 'SR',
            },
            {
              label: 'V',
              beValue: 'V',
            },
            {
              label: 'VI',
              beValue: 'VI',
            },
            {
              label: 'VII',
              beValue: 'VII',
            },
            {
              label: 'VIII',
              beValue: 'VIII',
            },
          ],
        },
        email: {
          label: 'Email Address',
          placeHolder: 'Email Address',
          isHidden: false,
        },
        relationship: {
          label: 'Relationship to Applicant',
          placeHolder: 'Select Relationship',
          isHidden: false,
          options: [
            {
              label: 'spouse',
              beValue: 'spouse',
            },
            {
              label: 'other',
              beValue: 'other',
            },
          ],
        },
        selectStatus: {
          placeHolder: 'Select Status',
          label: 'Marital Status',
          isHidden: false,
          options: [
            {
              label: 'married',
              beValue: 'MARRIED',
            },
            {
              label: 'separated',
              beValue: 'SEPARATED',
            },
            {
              label: 'unmarried',
              beValue: 'UNMARRIED',
            },
          ],
        },
        dateBirth: {
          placeHolder: 'MM/DD/YYYY',
          label: 'Date of Birth',
          isHidden: false,
        },
        SSN: {
          label: 'Social Security Number (SSN)',
          placeHolder: '### - ## - ####',
          isHidden: false,
        },
        homePhone: {
          label: 'Home Phone (optional)',
          placeHolder: '(###) ### - ####',
          isHidden: false,
        },
        workPhone: {
          label: 'Work Phone (optional)',
          placeHolder: '(###) ### - ####',
          isHidden: false,
        },
        mobilePhone: {
          label: 'Mobile Phone',
          placeHolder: '(###) ### - ####',
          isHidden: false,
        },
        emailAddress: {
          label: 'Email Address',
          placeHolder: 'you@email.com',
          isHidden: false,
        },
        contactMethod: {
          label: 'Preferred Contact Method',
          placeHolder: 'Select Preference',
          isHidden: false,
          options: [
            {
              label: 'email',
              beValue: 'Email',
            },
            {
              label: 'home',
              beValue: 'home',
            },
            {
              label: 'work',
              beValue: 'work',
            },
            {
              label: 'cell',
              beValue: 'cell',
            },
          ],
        },
        citizenship: {
          label: 'Citizenship',
          placeHolder: 'Select Status',
          isHidden: false,
          options: [
            {
              label: 'U.S. Citizen',
              beValue: 'USCITIZEN',
            },
            {
              label: 'Permanent Resident',
              beValue: 'PERMRESIDENT',
            },
            {
              label: 'Non-resident Alien',
              beValue: 'NONRESIDENTALIEN',
            },
          ],
        },
      },
    },
    button: {
      add: {
        text: 'Add Co-Borrower',
        isHidden: false,
      },
      remove: {
        text: '- Remove Co-Borrower',
        isHidden: false,
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  employedInformation: {
    currencyEmployment: {
      text: 'Current Employment',
      isHidden: false,
    },
    employmentType: {
      label: 'Employment Type',
      placeHolder: 'Select',
      isHidden: false,
      options: [
        {
          label: 'Employed',
          beValue: 'Employed',
          endDateRequired: false,
        },
        {
          label: 'Retired',
          beValue: 'Retired',
          endDateRequired: true,
        },
        {
          label: 'Military',
          beValue: 'Military',
          endDateRequired: false,
        },
        {
          label: 'Self Employed',
          beValue: 'Self Employed',
          endDateRequired: false,
        },
      ],
    },
    yourEmployment: {
      label: 'Employer Name',
      placeHolder: 'Employer Name',
      isHidden: false,
    },
    jobTitle: {
      label: 'Job Title or Position',
      placeHolder: 'Job Title',
      isHidden: false,
    },
    grossMonthlyIncome: {
      label: 'Gross Monthly Income',
      placeHolder: '$0.00',
      isHidden: false,
    },
    employmentStartDate: {
      label: 'Employment Start Date',
      placeHolder: 'MM/DD/YYYY',
      isHidden: false,
    },
    employmentEndDate: {
      label: 'Employment End Date',
      placeHolder: 'MM/DD/YYYY',
      isHidden: false,
    },
    current: {
      label: 'Current Employment',
      beValue: 'Current',
      placeholder: 'Current',
      isHidden: false,
    },
    typeOfIncome: {
      titleIncome: {
        text: 'Other Sources of Income',
        isHidden: false,
      },
      subTitleIncome: {
        text: 'Alimony, child support or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repayment.',
        isHidden: false,
      },
      label: {
        text: 'Type of Income',
        isHidden: false,
      },
      placeHolder: {
        text: 'Type of Income',
        isHidden: false,
      },
    },
    totalCurrencyIncome: 'Total current monthly income:',
    totalEmploymentHistory: 'Total employment history: 0 years (0 months)',
    consent: {
      text: 'I would like to continue my loan inquiry with less than 2 years employment history.',
      isHidden: false,
    },
    information: {
      text: 'We need a minimum of 2 years employment information.',
      isHidden: false,
    },
    button: {
      employment: {
        add: 'Employment',
        remove: '- Remove Employment',
        isHidden: false,
      },
      income: {
        add: 'Additional Income',
        remove: '- Remove Income',
        isHidden: false,
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  declarations: {
    form1003DeclarationQuestions: [
      {
        id: 'hasOutstandingJudgement',
        verbiage: 'Have you any outstanding judgements?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasBankruptcy',
        verbiage: 'Have you ever been declared bankrupt within the past 7 years?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasBadLoan',
        verbiage: 'Have you had property foreclosed upon or given a deed in lieu of foreclosure in the last 7 years?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'isInLawsuit',
        verbiage: 'Are you a party to a lawsuit?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'isRelatedToBadLoan',
        verbiage: 'Have you directly or indirectly been obligated on any loan which resulted in foreclosure, transfer of title in lieu of foreclosure, or judgment?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'isPresentlyDelinquent',
        verbiage: 'Are you presently delinquent or in default on any Federal debt or any other loan, mortgage, financial obligation, bond, or loan guarantee?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasSeparateMaintenance',
        verbiage: 'Are you obligated to pay alimony, child support, or separate maintenance?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'isDownPaymentBorrowed',
        verbiage: 'Is any part of the down payment borrowed?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'isEndorserOnNote',
        verbiage: 'Are you a co-maker or endorser on a note?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'IsUSCitizen',
        verbiage: 'Are you a U.S. citizen?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'IsPermanentResidentAlien',
        verbiage: 'Are you a permanent resident alien?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'isPropertyPrimaryResidence',
        verbiage: 'Do you intend to occupy the property as your primary residence?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasOwnerShipInterest',
        verbiage: 'Have you had an ownership interest in a property in the last three years?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
    ],
    generalDeclarationQuestions: [
      {
        id: 'hasJudgeBankruptForeclosure',
        verbiage:
          'Do you currently have any outstanding judgments or have you ever filed for bankruptcy, had a debt adjustment plan confirmed under Chapter 13, Had Property Foreclosed Upon or Repossessed in the Last 7 years, or been a party in a lawsuit?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasDeclareBankrupt',
        verbiage: 'Have you ever been declared bankrupt or filed a petition for chapter 7 or 13?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasChapter13',
        verbiage: 'Have you ever filed for bankruptcy or had a debt adjustment plan confirmed under chapter 13 in the past 10 years?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasJudgement',
        verbiage: 'Have you any outstanding judgements?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasLawsuitParty',
        verbiage: 'Are you a party to a lawsuit?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasForeclosure',
        verbiage: 'Have you had property foreclosed upon or given a deed in lieu of foreclosure in the last 7 years?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasReposession',
        verbiage: 'Have you ever had any auto, furniture or property repossessed?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasOtherObligation',
        verbiage: 'Have you any obligations not listed?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasPastDueBills',
        verbiage: 'Do you have any past due bills?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasCoMaker',
        verbiage: 'Are you a Co-maker, Co-signer or Guarantor on any loan?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasAlias',
        verbiage: 'Have you ever had credit in any other name?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasIncomeDecline',
        verbiage: 'Is Your Income Likely to Decline in the next two years?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasSuitsPending',
        verbiage: 'Have you any suits pending, judgments filed, alimony or support awards against you?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasAlimony',
        verbiage: 'Are you obligated to make Alimony, Support or Maintenance Payments?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
      {
        id: 'hasPropertyForSale',
        verbiage: 'Is the property securing this loan you are applying for currently for sale?',
        requiresExplanation: true,
        includeInQuestions: false,
      },
    ],
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  governmentMonitoringInformation: {
    title: {
      text: 'Applicant:',
      isHidden: false,
    },
    labels: {
      applicant: {
        text: 'Applicant',
        isHidden: false,
      },
      coApplicant: {
        text: 'Co-Applicant',
        isHidden: false,
      },
    },
    subtitle: {
      text: 'The purpose of collecting this information is to help ensure that all applicants are treated fairly and that the housing needs of communities and neighborhoods are being fulfilled. For residential mortgage lending, Federal law requires that we ask applicants for their demographic information (ethnicity, race, and sex) in order to monitor our compliance with equal credit opportunity, fair housing, and home mortgage disclosure laws. You are not required to provide this information, but are encouraged to do so. You may select one or more designations for "Ethnicity" and one or more designations for "Race". The law provides that we may not discriminate on the basis of this information, or on whether you choose to provide it. However, if you choose not to provide the information and you have made this application in person, Federal regulations require us to note your ethnicity, race, and sex on the basis of visual observation or surname. If you do not wish to provide some or all of this information, please check below.',
      isHidden: false,
    },
    checkList: {
      primaryEthnicity: {
        isHidden: false,
        notWish: 'I do not wish to furnish this information',
        notHispanic: 'Not Hispanic or Latino',
        hispanic: 'Hispanic or Latino',
        hispanicMexican: 'Mexican',
        hispanicPuertoRican: 'Puerto Rican',
        hispanicCuban: 'Cuban',
        hispanicOther: 'Other Hispanic or Latino',
      },
      raceGroup: {
        isHidden: false,
        notProvided: 'I do not wish to furnish this information',
        asianIndian: 'Asian Indian',
        asianChinese: 'Chinese',
        asianFilipino: 'Filipino',
        asianJapanese: 'Japanese',
        asianKorean: 'Korean',
        asianVietnamese: 'Vietnamese',
        asianOther: 'Other Asian',
        otherAsianPlaceholder: 'Other Asian Description',
        pacificIslander: 'Native Hawaiian/Other Pacific Islander',
        pacificIslanderHawaiian: 'Native Hawaiian',
        pacificIslanderGuamanian: 'Guamanian or Chamorro',
        pacificIslanderSamoan: 'Samoan',
        pacificIslanderOther: 'Other Pacific Islander',
        pacificOther: 'ther Pacific Islander Description',
        black: 'Black or African American',
        americanIndianOrAlaskaNative: 'American Indian or Alaska Native',
        otherAmericanOrAlaskan: 'Name of enrolled/principal tribe',
      },
      sexProperty: {
        isHidden: false,
        notProvided: 'I do not wish to furnish this information',
        male: 'Male',
        female: 'Female',
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  summary: {
    programInformationLink: {
      textOne: {
        text: '<a href="https://www.example.com" target="_blank">HELOC important terms</a>',
        isHidden: false,
      },
      textTwo: {
        text: '<a href="https://www.example.com" target="_blank">What you should know about HELOC</a>',
        isHidden: false,
      },
    },
    borrower: {
      title: {
        text: 'Review Your Application',
        isHidden: false,
      },
      applicant: {
        text: 'Applicant',
        isHidden: false,
      },
      subTitle: {
        text: 'Please review your application information for accuracy.',
        isHidden: false,
      },
      summaryContainer: [
        {
          section: 'basicInformation',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'Basic Information',
            },
            {
              key: 'fullName',
              value: '%%name%%',
            },
            {
              key: 'email',
              value: '%%email%%',
            },
            {
              key: 'birthday',
              value: '%%birthday%%',
            },
            {
              key: 'home',
              value: '%%homePhone%%',
            },
            {
              key: 'maritalStatus',
              value: '%%maritalStatus%%',
            },
            {
              key: 'cell',
              value: '%%cellPhone%%',
            },
            {
              key: 'preferredContact',
              value: '%%contactMethod%%',
            },
            {
              key: 'work',
              value: '%%workPhone%%',
            },
          ],
        },
        {
          section: 'propertyInformation',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'Property Information',
            },
            {
              key: 'propertyAddress',
              value: '%%address%%',
            },
            {
              key: 'propertyType',
              value: '%%propertyType%%',
            },
            {
              key: 'propertyUse',
              value: '%%propertyUse%%',
            },
          ],
        },
        {
          section: 'estimatedAvailableEquity',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'Estimated Available Equity',
            },
            {
              key: 'estimatedMarketValue',
              value: '%%estimatedMarket%%',
            },
            {
              key: 'remainingMortgageBalance',
              value: '%%remainingMortgage%%',
            },
          ],
        },
        {
          section: 'loanDetails',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'Loan Details',
            },
            {
              key: 'lineOfCredit',
              value: '%%lineAmount%%',
            },
            {
              key: 'initialDrawAmount',
              value: '%%drawAmount%%',
            },
          ],
        },
        {
          section: 'employment',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'Employment',
            },
            {
              key: 'employer',
              value: '%%employer%%',
            },
            {
              key: 'monthlyIncome',
              value: '%%income%%',
            },
            {
              key: 'joined',
              value: '%%startDate%%',
            },
            {
              key: 'totalMonths',
              value: '%%totalMonths%%',
            },
          ],
        },
        {
          section: 'HMDADetails',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'HMDA Details',
            },
            {
              key: 'Ethnicity',
              value: '%%ethnicity%%',
            },
            {
              key: 'race',
              value: '%%race%%',
            },
            {
              key: 'Gender',
              value: '%%gender%%',
            },
          ],
        },
      ],
    },
    coBorrower: {
      title: {
        text: 'Review Your Application',
        isHidden: false,
      },
      applicant: {
        text: 'Co-Applicant',
        isHidden: false,
      },
      subTitle: {
        text: 'Please review your application information for accuracy.',
        isHidden: false,
      },
      consumer: {
        text: 'Carson Testcase',
        isHidden: false,
      },
      summaryContainer: [
        {
          section: 'basicInformation',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'Basic Information',
            },
            {
              key: 'fullName',
              value: '%%nameCoBorrower%%',
            },
            {
              key: 'email',
              value: '%%emailCoBorrower%%',
            },
            {
              key: 'birthday',
              value: '%%birthdayCoBorrower%%',
            },
            {
              key: 'home',
              value: '%%homePhoneCoBorrower%%',
            },
            {
              key: 'maritalStatus',
              value: '%%maritalStatusCoBorrower%%',
            },
            {
              key: 'cell',
              value: '%%cellPhoneCoBorrower%%',
            },
            {
              key: 'preferredContact',
              value: '%%contactMethodCoBorrower%%',
            },
            {
              key: 'work',
              value: '%%workPhoneCoBorrower%%',
            },
          ],
        },
        {
          section: 'employment',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'Employment',
            },
            {
              key: 'employer',
              value: '%%employerCoBorrower%%',
            },
            {
              key: 'monthlyIncome',
              value: '%%incomeCoBorrower%%',
            },
            {
              key: 'joined',
              value: '%%startDateCoBorrower%%',
            },
            {
              key: 'totalMonths',
              value: '%%totalMonthsCoBorrower%%',
            },
          ],
        },
        {
          section: 'HMDADetails',
          isHidden: false,
          items: [
            {
              key: 'title',
              value: 'HMDA Details',
            },
            {
              key: 'Ethnicity',
              value: '%%ethnicityCoBorrower%%',
            },
            {
              key: 'race',
              value: '%%raceCoBorrower%%',
            },
            {
              key: 'Gender',
              value: '%%genderCoBorrower%%',
            },
          ],
        },
      ],
    },
    modalSubmit: {
      infoSubmitModal: {
        header: 'Review Notice',
        body: 'Custom  message. I’ve read and accept PW bank to email me, call me, and this is general counsel compliance language that effectively allows the bank to email the loan applicant with referral information, adverse action information, or genral contact to complete their application.',
        button: 'Cancel',
        acceptButton: 'Submit Application',
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  completion: {
    title: {
      text: 'Prequalification Terms',
      isHidden: false,
    },
    loanDetails: [
      {
        title: 'Loan number',
        value: '%%loanNumber%%',
        isHidden: false,
      },
      {
        title: 'Line Amount',
        value: '%%lineAmount%%',
        isHidden: false,
      },
      {
        title: 'Initial Disbursement',
        value: '%%drawAmount%%',
        isHidden: false,
      },
      {
        title: 'Initial Rate and APR*',
        value: '%%apr%%',
        isHidden: false,
      },
    ],
    verbiage: {
      text: '*Quoted interest rate is the initial interest rate for your HELOC and is subject to change without notice. Please note that the interest rate does not include costs other than interest and will vary over the life of your loan. Rate and terms are not guaranteed and may change before account opening without notice. Credit and collateral approval is required. Final initial rate will be set at the time application is fully underwritten.',
      isHidden: false,
    },
    verbiageTwo: {
      text: 'To be eligible for the quoted rate, a credit union membership may be required. loanDepot will still be your HELOC lender and we or one of our service providers will service your loan. The selected credit union will fund an account in your name and will provide you membership disclosures and account statements, but we will not ask you to make any further financial commitment. If you choose to opt out of credit union membership, the interest rate may be up to 3 percentage points higher.',
      isHidden: false,
    },
    nextSteps: {
      title: {
        text: 'Thank you for applying. You are only 4 steps away from getting access to your funds!',
        isHidden: false,
      },
      subTitle: {
        text: 'Thank you for applying. You are only 4 steps away from getting access to your funds!',
        isHidden: false,
      },
      verbiage: {
        text: "<br><b>STEP 1: E-sign your Disclosures </b> in the Borrower Portal.<br><br><b>STEP 2: To-Do Tasks. </b><br><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; Finish any outstanding Tasks on your Borrower Portal.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; ID Verification: You will receive a text from loanDepot with a secure link that allows us to verify your identity. We take security seriously, and verifying your selfie and government-issued ID helps us confirm and protect your identity.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; Proxy Pics: You will receive a text from loanDepot with a secure link that allows you to take a few photos of the outside of your home. Once you download the App, you will need to allow Proxy Pics to access your location so it can locate your property. Remember all pictures must be taken in the app for security reasons.</p><br><b>STEP 3:</b> <span style='color: #9900FF;'>Green Light!</span> Once you have provided the necessary information and documentation, now the loanDepot Team can review your file and determine final approval.<br><br><b>STEP 4: Closing.</b> We are now ready to set your closing date.<br><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; A Closing Agent will be in touch with you to determine the time and location of your closing.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; Your initial draw amount will be made available to you in your preferred account, along with instructions on setting up your online HELOC portal for subsequent draws and payments.</p>",
        isHidden: false,
      },
      description: {
        text: 'We sent a temporary password to the email address you provided in your application.',
        isHidden: false,
      },
      button: {
        text: 'Go To My Portal!',
        redirectTo: 'https://www.example.com',
        isHidden: false,
      },
      contact: {
        text: 'sPlease log into the secure portal by clicking on the link sent to the email address provided in the application. Once logged in, you can provide, review and sign documents directly from the portal.',
        isHidden: false,
      },
      verbiageLd: {
        textOne: {
          text: 'Start bringing your dreams to life…… It’s really that easy!',
          isHidden: false,
        },
        textTwo: {
          text: 'If you have any questions regarding your application, please call us at (866) 790-3940',
          isHidden: false,
        },
      },
      isButtonPortal: true,
    },
  },
  footer: {
    defaultFooter: {
      footerDisclosuresOne: {
        text: '©2023 Lender Name (NMLS #12345)',
        isHidden: false,
      },
      footerDisclosuresTwo: {
        text: 'Contact Us 866.243.1111 current',
        isHidden: false,
      },
      support: {
        text: 'current version 0.2.60.3',
        isHidden: false,
      },
      compliance: {
        logo: {
          url: 'https://firstclosestagingmedia.blob.core.windows.net/media/4_ml_internal_application/Equal-Housing-Opportunity-logo-52-BB024373-seeklogo-com.png',
          isHidden: false,
          text: 'description text to logo one',
        },
        logo2: {
          url: 'https://firstclosestagingmedia.blob.core.windows.net/media/4_ml_internal_application/Blk-ENGinsurlabel.jpg',
          isHidden: false,
          text: 'description text to logo two',
        },
      },
    },
  },
  footerVmoV2: {
    footerDisclosuresOne: {
      text: '*Rates and terms are not guaranteed and may change before account opening without notice. Rates and terms may vary based on requested line amount, credit history and available equity. Final initial rate will be set at the time application is fully underwritten. To check the rates and terms to which you may qualify, we will conduct a soft credit pull that will not impact your credit score. If you continue and submit an inquiry, however, we will request your full credit report from one or more consumer reporting agencies which is considered a hard credit pull and may affect your credit score.',
      isHidden: false,
    },
    footerDisclosuresTwo: {
      text: '<a href="#">loanDepot.com LLC </a>, an Equal Housing Opportunity Lender, 6561 Irvine Center Drive, Irvine, CA 92618 All rights reserved. NMLS #174457 - NMLS Consumer Access Site. To view important notices regarding loanDepot licensing, please visit <a href="#">www.loandepot.com/licensing.</a>',
      isHidden: false,
    },
    support: {
      text: 'current version 0.2.60.3',
      isHidden: false,
    },
    compliance: {
      logo: {
        url: 'https://firstclosestagingmedia.blob.core.windows.net/media/4_ml_internal_application/Equal-Housing-Opportunity-logo-52-BB024373-seeklogo-com.png',
        isHidden: false,
        text: 'description text to logo one',
      },
      logo2: {
        url: 'https://firstclosestagingmedia.blob.core.windows.net/media/4_ml_internal_application/Blk-ENGinsurlabel.jpg',
        isHidden: false,
        text: 'description text to logo two',
      },
    },
  },
};
