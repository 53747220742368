import React, { useState } from 'react';
import ProgramCard from '@/components/Layout/ProgramCard/ProgramCard';
import { DebtConsolidation } from '@/components/Layout/DebtConsolidation/DebtConsolidation';
import ProgramSelector from '@/components/ProgramSelector/ProgramSelector';
import CalculateAmount from '@/components/CalculateAmount/CalculateAmount';
import { CardsContainer, SubtitleContainer } from './VmoV2.styles';
import { Wrapper } from '../wrapper.styles';
import { TextBlock, TypeOfText } from '@/components/text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { useNavigate } from 'react-router-dom';
import { FooterNewVmo } from '@/components/FooterNewVmo/FooterNewVmo';
import { useIsMobile } from '@/hooks/useIsMobile';
interface VmoV2Props {
  onAccess: (page: string) => void;
}
const VmoV2: React.FC<VmoV2Props> = ({ onAccess }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  const [isReprice, setIsReprice] = useState<boolean>(false);

  const isDisabledNext = Object.keys(LoanApplication?.selectedProgram).length === 0 || LoanApplication?.selectedProgram?.programName === '';
  return (
    <>
      <Wrapper $isMobile={isMobile} $padding="0rem">
        <ProgramSelector />
        <SubtitleContainer>
          <TextBlock
            text={templateConfig?.pages?.vmo?.vmoV2?.titleProgramSection?.title || 'Enter your desired line amount to compare and choose between your offers.'}
            direction={'center'}
            type={TypeOfText.Subtitle}
            isHidden={templateConfig?.pages?.vmo?.vmoV2?.titleProgramSection?.isHidden || false}
          />
          <TextBlock
            text={templateConfig?.pages?.vmo?.vmoV2?.subtitleProgramSection?.title || 'Pick the HELOC that’s right for you'}
            direction={'center'}
            type={TypeOfText.Subtitle}
            isHidden={templateConfig?.pages?.vmo?.vmoV2?.subtitleProgramSection?.isHidden || false}
          />
        </SubtitleContainer>
        <CardsContainer>
          <CalculateAmount setIsReprice={setIsReprice} />
          <ProgramCard isReprice={isReprice} />
        </CardsContainer>
      </Wrapper>
      <DebtConsolidation isReprice={isReprice} />
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.vmo?.vmoV2?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.vmo?.vmoV2?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.vmo?.vmoV2?.NavigationButton?.backButton?.text || 'Back'}
        disabled={isDisabledNext}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.ESTIMATED_AVAILABLE_EQUITY}
        currentPage={PageOrigin.VMO}
        destinationPage={PageOrigin.PERSONAL_INFORMATION}
      />
      <FooterNewVmo />
    </>
  );
};

export default VmoV2;
