import React, { Fragment } from 'react';
import { ItemBody, Section, SectionBody, SectionHeader, Separator, WrapperTitle, WrapperSubtitle } from './SectionSummary.styles';
import { TextBlock, TypeOfText } from '../text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { formatToTitleCase } from '@/utils/formatToLabelCase';
import { parseSummaryValues } from '@/utils/parseInputValue';
import { formatCurrency } from '@/utils/formatValues';
interface Summary {
  title: {
    isHidden: boolean;
    text: string;
  };
  applicant: {
    isHidden: boolean;
    text: string;
  };
  subTitle: {
    isHidden: boolean;
    text: string;
  };
  consumer: {
    isHidden: boolean;
    text: string;
  };
  summaryContainer: [
    {
      section: string;
      isHidden: boolean;
      items: [
        {
          key: string;
          value: string;
        },
      ];
    },
  ];
}

interface SectionSummary {
  summary: Summary;
  isCoBorrower?: boolean;
}
const SectionSummary: React.FC<SectionSummary> = ({ summary, isCoBorrower }) => {
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const { title, subTitle, summaryContainer, applicant } = summary;
  const isApplicant = applicant?.text === 'Applicant';
  const borrowerIndex = isApplicant ? 0 : 1;
  const employer = loanApplication?.borrowers[borrowerIndex]?.employmentHistory.length;
  const incomeArray: number[] = [];
  const coApplicantLabel = templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.coApplicantLabel || 'Co-Applicant';

  loanApplication?.borrowers.forEach((borrower) => {
    const totalIncome =
      borrower.otherIncome?.reduce((total, income) => {
        return total + (income.grossMonthlyIncome || 0);
      }, 0) || 0;

    incomeArray.push(totalIncome);
  });
  const employmentSections = loanApplication?.borrowers[borrowerIndex]?.employmentHistory.map((employment, index) => ({
    section: `employment_${index}`,
    items: [
      ...(index === 0 ? [{ key: 'title', value: 'Employment Details' }] : [{ key: 'title', value: '' }]),
      { key: 'employer', value: employment.employerName },
      { key: 'totalMonths', value: employment.totalMonths ? employment.totalMonths.toString() : '' },
      { key: 'joined', value: employment.startDate },
      ...(employer === 1
        ? [{ key: 'monthlyIncome', value: formatCurrency(employment.grossMonthlyIncome + incomeArray[borrowerIndex]) || '' }]
        : index + 1 === employer
          ? [{ key: 'monthlyIncome', value: formatCurrency(employment.grossMonthlyIncome + incomeArray[borrowerIndex]) || '' }]
          : [{ key: 'title', value: '' }]),
    ],
  }));

  const employmentIndex = summaryContainer.findIndex((section) => section.section === 'employment');

  const updatedSummaryContainer = [...summaryContainer.slice(0, employmentIndex), ...employmentSections, ...summaryContainer.slice(employmentIndex + 1)];
  return (
    <>
      {isApplicant && (
        <>
          <WrapperTitle>
            <TextBlock text={title.text || 'Review Your Application'} direction={'flex-start'} color="default" type={TypeOfText.H2} isHidden={title?.isHidden || false} />
          </WrapperTitle>
          <WrapperSubtitle>
            <TextBlock
              text={subTitle.text || 'Please review your application information for accuracy.'}
              direction={'flex-start'}
              color="default"
              type={TypeOfText.Subtitle}
              isHidden={subTitle?.isHidden || false}
            />
          </WrapperSubtitle>
        </>
      )}
      <TextBlock
        text={isCoBorrower ? applicant.text?.replace('%%coApplicantLabel%%', coApplicantLabel) || 'Co-Applicant' : applicant.text || 'Applicant'}
        direction={'flex-start'}
        color="default"
        type={TypeOfText.H2}
        isHidden={applicant?.isHidden || false}
      />
      {updatedSummaryContainer.map((section, index) => {
        const sectionData = section.items;
        const fullWidth = index !== 0;
        return (
          <Fragment key={index}>
            <Separator />
            <Section key={index}>
              <SectionHeader>
                <TextBlock text={sectionData.find((item) => item.key === 'title')?.value || ''} direction={'flex-start'} color="default" type={TypeOfText.H3} />
              </SectionHeader>
              <SectionBody $fullWidth={fullWidth}>
                {sectionData.map((item, idx) => {
                  if (item.key === 'monthlyIncome') {
                    return (
                      <Fragment key={idx}>
                        <Separator $isTotalMonths={true} />
                        <ItemBody $isTotalMonths={true}>
                          <TextBlock text={`${formatToTitleCase(item.key)}:` || 'Unknown'} direction="flex-start" width="30%" type={TypeOfText.Subtitle} />
                          <TextBlock text={parseSummaryValues(item?.value, loanApplication)} direction="flex-end" color="default" width="70%" type={TypeOfText.Subtitle} />
                        </ItemBody>
                      </Fragment>
                    );
                  } else if (item.key !== 'title') {
                    return (
                      <ItemBody key={idx} $isBasicInformation={section.section === 'basicInformation'}>
                        <TextBlock text={`${formatToTitleCase(item.key)}:` || 'Unknown'} direction="flex-start" width="30%" type={TypeOfText.Subtitle} />
                        <TextBlock text={parseSummaryValues(item?.value, loanApplication)} direction="flex-end" color="default" width="70%" type={TypeOfText.Subtitle} />
                      </ItemBody>
                    );
                  }
                  return null;
                })}
              </SectionBody>
            </Section>
          </Fragment>
        );
      })}
    </>
  );
};

export default SectionSummary;
