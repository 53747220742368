import React, { useState } from 'react';
import { Button } from '@/components/Button/DefaultButton/Button';
import { Modal, ModalTitle, ModalBody, ModalContent, ModalFooter, BackDrop, ModalInput, Spinner, Divider } from './ModalSubmit.styles';
import DatePicker from '@/components/input/DatePicker/DatePicker';
import { handleNext } from '@helper/navigationHelpers';
import { useNavigate } from 'react-router-dom';
import { pages } from '../../../defaultVerbiages';
import { Slider } from '@/components/Slider/Slider';
import { makePostCall } from '@/services/makePostCall';
import SafeHTML from '@/utils/safeHTML';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { startLoading, stopLoading, selectIsLoading } from '@/reducers/currentPage';
import { DefaultModal } from '../DefaultModal/DefaultModal';
import { performStatedCreditPatch } from '@/utils/handleEndpoints';
import { makePatchCall } from '@/services/makePatchCall';
import { TextInput } from '@/components/input/TextInput/TextInput';

export interface ModalSubmitProps {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  onAccess: (page: string) => void;
  infoSubmitModal: {
    label?: string;
    header?: string;
    body?: string;
    button: string;
    textOne?: string;
    textTwo?: string;
  };
}

type Borrower = {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffixName: string;
  isMailingAddressSameAsProperty: boolean;
  propertyOccupancyDate: string;
};
type Property = {
  city: string;
  county: string;
  loanPurposeType: string;
  postalCode: string;
  propertyType: string;
  propertyUseType: string;
  state: string;
  streetAddress: string;
  unitType: string;
};
type IcePayload = {
  borrower: Borrower;
  loanFolder: string;
  property: Property;
};

export const ModalSubmit: React.FC<ModalSubmitProps> = ({ openModal, setOpenModal, infoSubmitModal, onAccess }) => {
  let currentForm = useAppSelector((state) => state.CurrentForm);
  const navigate = useNavigate();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);

  let isScoreNeeded = !currentForm?.softPullConsent;
  const [scoreNeeded, setScoreNeeded] = useState(isScoreNeeded);
  const [error, setError] = useState<string | unknown>('');
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const canContinue = loanApplication?.borrowers[0]?.borrowerDOB !== '';

  const sliderConfig = useAppSelector((state) => state.TemplateConfig?.pages?.slider?.score) || [];
  const globalMin = sliderConfig[0]?.minRank || 300;
  const globalMax = sliderConfig[sliderConfig.length - 1]?.maxRank || 850;

  const [creditScore, setCreditScore] = useState<number>(globalMin);

  const handleSliderChange = (value: number) => {
    setCreditScore(value);
  };

  const handleInputChange = (value: string | number) => {
    const numericValue = parseInt(value.toString(), 10);
    if (!isNaN(numericValue) && numericValue >= globalMin && numericValue <= globalMax) {
      setCreditScore(numericValue);
    }
  };

  const closeModal = (action?: any) => {
    setOpenModal(false);

    if (action === 'next') {
      handleNext(navigate, onAccess, 'get-started', '/get-started-address');
    }
  };

  const createIcePayload = (loanApplication: any): IcePayload => {
    const {
      propertyType,
      propertyPrimaryUse,
      borrowers,
      borrowerProperty: { propertyOccupancyDate, propertyCity, propertyCountry, propertyZip, propertyState, propertyStreetAddress },
    } = loanApplication;
    return {
      borrower: {
        email: borrowers[0].borrowerEmailAddress,
        firstName: borrowers[0].borrowerFirstName,
        lastName: borrowers[0].borrowerLastName,
        middleName: '',
        suffixName: '',
        isMailingAddressSameAsProperty: true,
        propertyOccupancyDate,
      },
      loanFolder: 'Training',
      property: {
        city: propertyCity,
        county: propertyCountry,
        loanPurposeType: borrowers[0].borrowerLoanPurpose,
        postalCode: propertyZip,
        propertyType: propertyType,
        propertyUseType: propertyPrimaryUse,
        state: propertyState,
        streetAddress: propertyStreetAddress,
        unitType: '',
      },
    };
  };

  const processClick = async () => {
    const isICE = templateConfig?.isICE ?? false;
    const isResume = loanApplication.isEligible ?? false;
    dispatch(startLoading());
    try {
      if (!scoreNeeded || isICE) {
        const endpointSoftpull = isResume ? '/file/%%loanId%%/softpull-credit' : '/file/softpull-credit';

        const endpoint = isICE ? `/loans` : endpointSoftpull;
        const payload = isICE ? createIcePayload(loanApplication) : loanApplication;
        const newAction = {
          actionProperties: {
            endpoint,
          },
        };

        const softPullResponse = isResume
          ? await makePatchCall(newAction, loanApplication, loanApplication, dispatch, templateConfig)
          : await makePostCall(newAction, loanApplication, payload, dispatch, templateConfig);
        if (!isResume && softPullResponse?.data[0]?.payload?.statedCreditRequired) {
          setScoreNeeded(true);
          dispatch(stopLoading());
        } else if (isResume && softPullResponse?.payload?.borrowers[0].statedCreditRequired) {
          setScoreNeeded(true);
          dispatch(stopLoading());
        } else {
          closeModal('next');
        }
      } else {
        dispatch(startLoading());
        await performStatedCreditPatch(loanApplication, dispatch, templateConfig);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.error('Error processing click:', error);
      setError(error);
    } finally {
      dispatch(stopLoading());
    }
  };
  return (
    <>
      <BackDrop $isOpen={openModal} onClick={closeModal} data-testid="modal-backdrop" />
      {!error && (
        <Modal $isOpen={openModal} $isLoading={isLoading}>
          {isLoading && (
            <ModalBody>
              <Spinner />
              <ModalTitle $isLoading={isLoading}>{pages.LetsGetStarted.modals.loadingModal.textOne}</ModalTitle>
              <ModalContent $isLoading={isLoading}>{pages.LetsGetStarted.modals.loadingModal.textTwo}</ModalContent>
            </ModalBody>
          )}
          {!isLoading && scoreNeeded && (
            <ModalBody>
              <ModalTitle $isLoading={isLoading}>{templateConfig?.pages?.LetsGetStarted?.modals?.creditScoreNeeded?.title || 'Credit Score Needed'}</ModalTitle>
              <ModalContent $isLoading={isLoading}>
                <SafeHTML
                  tag="p"
                  html={
                    templateConfig?.pages?.LetsGetStarted?.modals?.creditScoreNeeded?.body ||
                    'We were unable to obtain credit with the information provided. <b>Please select the best estimate of your credit score below to continue.'
                  }
                />
              </ModalContent>
              <Slider value={creditScore} min={globalMin} max={globalMax} scoreConfig={sliderConfig} onChange={handleSliderChange} />
              <Divider />
              <TextInput type="number" value={creditScore.toString()} onChange={handleInputChange} minAmount={globalMin} maxAmount={globalMax} isCentered />
              <ModalFooter>
                <Button
                  label={templateConfig?.pages?.LetsGetStarted?.modals?.creditScoreNeeded?.button || 'Submit'}
                  onClick={async () => {
                    await processClick();
                    closeModal('next');
                  }}
                  variant="primary"
                  buttonSize="l"
                />
              </ModalFooter>
            </ModalBody>
          )}
          {!isLoading && !scoreNeeded && (
            <ModalBody>
              <ModalTitle $isLoading={isLoading}>{infoSubmitModal?.header}</ModalTitle>
              <ModalContent $isLoading={isLoading}>{infoSubmitModal?.body}</ModalContent>
              <ModalInput>{infoSubmitModal?.label}</ModalInput>
              <DatePicker
                propertyType={'dateBirthday'}
                propertyName={'birthData'}
                placeholder={'MM/DD/YYYY'}
                isHidden={false}
                value={loanApplication?.borrowers[0]?.borrowerDOB || ''}
              />
              <ModalFooter>
                <Button
                  isDisabled={!canContinue}
                  label={infoSubmitModal.button}
                  onClick={async () => {
                    await processClick();
                  }}
                  variant="primary"
                  buttonSize="l"
                />
              </ModalFooter>
            </ModalBody>
          )}
        </Modal>
      )}
      {openErrorModal && (
        <DefaultModal
          openModal={openErrorModal}
          setOpenModal={setOpenErrorModal}
          isErrorModal={openErrorModal}
          infoModal={{ body: error as string, button: 'Return to %%lenderName%%' }}
        />
      )}
    </>
  );
};
