import styled from 'styled-components';

interface ProgressProps {
  $percent: number;
  $barColor: string;
}

export const Container = styled.div`
  height: 0.438rem;
  width: 100%;
  position: relative;
`;

export const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.188rem;
  transition: width 0.5s ease-in-out;
`;

export const Background = styled(BaseBox)`
  background: ${(props) => props.theme.colors.bgProgressGrey};
  width: 100%;
`;

export const Progress = styled(BaseBox)<ProgressProps>`
  background: ${({ $barColor }) => $barColor && $barColor};
  width: ${({ $percent }) => $percent}%;
`;
