import React, { useState, useCallback, useEffect } from 'react';
import { TextBlock } from '@/components/text/TextBlock';
import { useDispatch } from 'react-redux';
import { WrapperContainer, SelectorContainer, ProgramButton, ProgramTitle, DescriptionContainer, TitleContainer, SubtitleContainer } from './ProgramSelector.style';
import { TypeOfText } from '@/components/text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { formatNumber } from '@/utils/formatValues';
interface Program {
  id: number;
  title: string;
  description: string;
  isHidden: boolean;
}

export interface ProgramSelectorProps {
  isHidden?: boolean;
  isCustomColor?: boolean;
  barCustomBackground?: string;
  disabledProgramBackground?: string;
  selectedProgramBackground?: string;
  activeTextBottomColor?: string;
  disabledTextBottomColor?: string;
  titleIsCustomColor?: boolean;
  titleCustomColor?: string;
  subtitleIsCustomColor?: boolean;
  subtitleCustomColor?: string;
}

const ProgramSelector: React.FC<ProgramSelectorProps> = ({
  isHidden = false,
  isCustomColor = false,
  barCustomBackground = '#3A149E',
  disabledProgramBackground = '#7363F1',
  selectedProgramBackground = '#FFFFFF',
  activeTextBottomColor = '#273141',
  disabledTextBottomColor = '#0e0b22',
  titleIsCustomColor = false,
  titleCustomColor = '#3A149E',
  subtitleIsCustomColor = false,
  subtitleCustomColor = '#3A149E',
}) => {
  const dispatch = useDispatch();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);

  const isVmoV2 = templateConfig?.features?.vmoRedesign?.isEnabled ?? false;
  const enabledPrograms: Program[] = templateConfig?.pages?.vmo?.programs || [
    {
      id: 1,
      title: 'Line of Credit',
      description:
        'A home equity line of credit (HELOC) is a line of credit secured by your home that gives you a revolving credit line to use for large expenses or to consolidate higher-interest rate debt on other loans such as credit cards.',
    },
    {
      id: 2,
      title: 'Home Equity Loan',
      description:
        'A home equity line of credit (HEL) is a line of credit secured by your home that gives you a revolving credit line to use for large expenses or to consolidate higher-interest rate debt on other loans such as credit cards.',
    },
  ];
  const typeOfPorgram = loanApplication?.availablePrograms.helocData === undefined;
  const initialSelectedProgram = typeOfPorgram || loanApplication?.selectedProgramType === 'Home Equity Loan' ? enabledPrograms[1] : enabledPrograms[0];
  const isSingleProgram = Object.entries(loanApplication?.availablePrograms).length === 1;
  const programs = isSingleProgram ? [initialSelectedProgram] : enabledPrograms;
  const [selectedProgram, setSelectedProgram] = useState<Program>(initialSelectedProgram);
  const programInfo = initialSelectedProgram.title === 'Line of Credit' ? 'helocData' : 'helData';
  const availablePrograms = templateConfig?.isICE ? loanApplication?.availablePrograms?.helRates : loanApplication?.availablePrograms?.[programInfo]?.rates;

  useEffect(() => {
    handleProgramTypeSelection(selectedProgram);
  }, []);
  const handleProgramTypeSelection = useCallback(
    (program: Program) => {
      setSelectedProgram(program);
      dispatch({
        type: 'LoanApplication/updateSpecificField',
        payload: {
          depth: ['selectedProgramType'],
          value: program.title,
        },
      });
    },
    [loanApplication], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const hasMultiPrograms = availablePrograms?.length > 1;
  const titleMaxAmount = templateConfig?.pages?.vmo?.titleOneProgram?.text
    ?.replace('%%maxLineAmount%%', formatNumber(availablePrograms?.[0]?.maxLineAmount || loanApplication?.minLineAmount))
    ?.replace('%%maxLoanAmount%%', formatNumber(availablePrograms?.[0]?.maxLoanAmount || loanApplication?.minLoanAmount));
  const titleProgram = hasMultiPrograms ? templateConfig?.pages?.vmo?.titleMultipleProgram?.text : titleMaxAmount;

  return (
    <WrapperContainer data-testid="program-selector">
      <TitleContainer $isHidden={isHidden} $titleIsCustomColor={titleIsCustomColor} $titleCustomColor={titleCustomColor}>
        <TextBlock
          text={titleProgram || 'Congratulations, you’re prequalified for a credit line up to $250,000!.'}
          type={TypeOfText.H1}
          isHidden={templateConfig?.pages?.vmo?.title?.isHidden ?? false}
        />
      </TitleContainer>
      <SubtitleContainer $isHidden={isVmoV2} $subtitleIsCustomColor={subtitleIsCustomColor} $subtitleCustomColor={subtitleCustomColor}>
        <TextBlock
          text={
            templateConfig?.pages?.vmo?.subtitle?.text ||
            'Compare your options by clicking between Line of Credit and Home Equity Loan and select the path that best suits your needs'
          }
          type={TypeOfText.Subtitle}
          isHidden={templateConfig?.pages?.vmo?.subtitle?.isHidden ?? false}
        />
      </SubtitleContainer>
      <SelectorContainer data-testid="selector-container" $isCustomColor={isCustomColor} $barCustomBackground={barCustomBackground}>
        {programs.map(
          (program) =>
            !program.isHidden && (
              <ProgramButton
                key={program.id}
                onClick={() => handleProgramTypeSelection(program)}
                $isSingleProgram={isSingleProgram}
                $activeTextBottomColor={activeTextBottomColor}
                $disabledTextBottomColor={disabledTextBottomColor}
                $isCustomColor={isCustomColor}
                $selectedProgramBackground={selectedProgramBackground}
                $disabledProgramBackground={disabledProgramBackground}
                $isSelected={selectedProgram?.id === program.id}
                $data-testid="program-selector-button"
              >
                <ProgramTitle $isSingleProgram={isSingleProgram}>{program.title}</ProgramTitle>
              </ProgramButton>
            ),
        )}
      </SelectorContainer>
      <DescriptionContainer>
        <TextBlock text={selectedProgram.description} type={TypeOfText.Text} />
      </DescriptionContainer>
    </WrapperContainer>
  );
};

export default ProgramSelector;
