import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextContainer,
  EquityGroup,
  Wrapper,
  EstimatedAvailableIcon,
  EstimatedValueIcon,
  EstimatedHomeIcon,
  RemainingMortgageIcon,
  TitleContainer,
  InfoContainer,
  InputContainer,
  ConfirmMessageContainer,
} from './EstimatedAvailableEquity.styles';
import { PropertyCard } from '@/components/Layout/PropertyCard/PropertyCard';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { TextBlock } from '@/components/text/TextBlock';
import { TextInput } from '@/components/input/TextInput/TextInput';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { get } from 'lodash';
import { formatNumber } from '@/utils/formatValues';
import { TypeOfText } from '@/components/text/TextBlock';
import { Button } from '@/components/Button/DefaultButton/Button';

interface EstimatedAvailableEquityProps {
  onAccess: (page: string) => void;
  pageOrigin: PageOrigin;
  customColor?: string;
  isCustomColor?: boolean;
  isDisabled?: boolean;
}

const EstimatedAvailableEquity: React.FC<EstimatedAvailableEquityProps> = ({ onAccess, customColor = '#5E6572', isCustomColor = false }) => {
  const [continueInquiry, setContinueInquiry] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  useEffect(() => {
    if (loanApplication.estimatedMarketValue === 0) {
      setError(true);
    } else {
      setError(false);
    }
  }, [loanApplication.remainingMortgageBalance, loanApplication.estimatedMarketValue]);

  const cltvPercentage = loanApplication?.cltv || 0;
  const calculateEstimatedHomeEquity = useCallback((cltvPercentage: number, estimatedMarketValue: number, estimatedMortgageBalance: number) => {
    const currentLTVMaxAmount = (cltvPercentage / 100) * estimatedMarketValue;
    return Math.trunc(currentLTVMaxAmount - estimatedMortgageBalance);
  }, []);

  const calculateRawHomeEquity = useCallback((estimatedMarketValue: number, estimatedMortgageBalance: number) => {
    return Math.trunc(estimatedMarketValue - estimatedMortgageBalance);
  }, []);

  const calculateAdjustedLineAmount = useCallback((estimatedHomeEquity: number, maxLineAmount: number, minLineAmount: number) => {
    const adjustedAmount = estimatedHomeEquity > maxLineAmount ? maxLineAmount : estimatedHomeEquity < minLineAmount ? minLineAmount : estimatedHomeEquity;
    return Math.trunc(adjustedAmount / 100) * 100;
  }, []);

  const handleContinueInquiry = useCallback(async () => {
    if (loanApplication?.estimatedMarketValue) setContinueInquiry(true);
  }, [loanApplication?.estimatedMarketValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = useCallback(
    (value: string, propertyType: 'estimate' | 'remaining') => {
      const inputValue = value.replace(/[\$,]/g, '');
      const numericValue = Number(inputValue);
      let maxLineAmount = get(loanApplication, ['maxLineAmount']);
      let estimate = propertyType === 'estimate' ? numericValue : get(loanApplication, ['estimatedMarketValue']);
      let remaining = propertyType === 'remaining' ? numericValue : get(loanApplication, ['remainingMortgageBalance']);

      let calculatedEstimatedHomeEquity = calculateEstimatedHomeEquity(cltvPercentage, estimate, remaining);
      const updatedLoanApplication = {
        ...loanApplication,
        estimatedMarketValue: estimate,
        remainingMortgageBalance: remaining,
        estimatedRawEquity: calculateRawHomeEquity(estimate, remaining),
        estimatedHomeEquity: calculateRawHomeEquity(estimate, remaining),
        lineAmount: calculateAdjustedLineAmount(calculatedEstimatedHomeEquity, maxLineAmount, 0),
      };
      dispatch({
        type: 'LoanApplication/updateValue',
        payload: updatedLoanApplication,
      });
    },
    [calculateAdjustedLineAmount, calculateEstimatedHomeEquity, calculateRawHomeEquity, cltvPercentage, dispatch, loanApplication],
  );
  return (
    <>
      <Container>
        <PropertyCard />
        <Wrapper>
          <EquityGroup $isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedMarket?.isHidden ?? false}>
            <EstimatedValueIcon />
            <InfoContainer>
              <TitleContainer>
                <TextBlock
                  text={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedMarket?.title || 'Estimated Market Value'}
                  type={TypeOfText.H2}
                  isCustomColor={isCustomColor}
                  customColor={customColor}
                />
              </TitleContainer>
              <TextContainer $isCustomColor={isCustomColor} $customColor={customColor}>
                <TextBlock
                  text={
                    templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedMarket?.text ||
                    'The Estimated Market Value is based on and automated valuation model (AVM) used by many lenders and real estate platforms to initiate home equity valuations.'
                  }
                  type={TypeOfText.Text}
                />
              </TextContainer>
            </InfoContainer>
            <InputContainer>
              <TextInput
                size="10%"
                placeholder="$0"
                onChange={handleChange}
                value={formatNumber(loanApplication?.estimatedMarketValue)}
                propertyType={'estimate'}
                isRequired={false}
              />
            </InputContainer>
          </EquityGroup>
          <EquityGroup $isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.remainingMortgage?.isHidden ?? false}>
            <RemainingMortgageIcon />
            <InfoContainer>
              <TitleContainer data-testid="title-container">
                <TextBlock
                  text={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.remainingMortgage?.title || 'Remaining Mortgage Balance'}
                  type={TypeOfText.H2}
                  isCustomColor={isCustomColor}
                  customColor={customColor}
                />
              </TitleContainer>
              <TextContainer $isCustomColor={isCustomColor} $customColor={customColor}>
                <TextBlock
                  text={
                    templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.remainingMortgage?.text ||
                    'The Remaining Mortgage Balance is an estimate of what you owe based on public information. You can adjust the amount remaining here, if the publicly available information is incorrect.'
                  }
                  type={TypeOfText.Text}
                  isCustomColor={isCustomColor}
                  customColor={customColor}
                />
              </TextContainer>
            </InfoContainer>
            <InputContainer>
              <TextInput
                size="10%"
                placeholder="$0"
                onChange={handleChange}
                propertyType={'remaining'}
                isRequired={false}
                value={formatNumber(loanApplication?.remainingMortgageBalance) ?? 0}
              />
            </InputContainer>
          </EquityGroup>
          {!continueInquiry && (
            <ConfirmMessageContainer data-testid="title-container">
              <TextBlock
                text={
                  templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.configureMessage?.text ||
                  'We were unable to retrieve the estimated market value and remaining mortgage balance for this property. You can manually enter the estimated market value and remaining mortgage balance, then click "confirm" to continue with your loan inquiry.'
                }
                type={TypeOfText.Text}
                isCustomColor={isCustomColor}
                customColor={customColor}
                isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.configureMessage?.isHidden ?? false}
              />
              <Button
                variant="primary"
                label={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.configureMessage?.button || 'Confirm'}
                onClick={handleContinueInquiry}
                buttonSize="l"
              />
            </ConfirmMessageContainer>
          )}
          {continueInquiry && (
            <>
              <EquityGroup $isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedHome?.isHidden ?? false}>
                <EstimatedHomeIcon />
                <InfoContainer>
                  <TitleContainer>
                    <TextBlock
                      text={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedHome?.title || 'Estimated Home Equity'}
                      type={TypeOfText.H2}
                      isCustomColor={isCustomColor}
                      customColor={customColor}
                    />
                  </TitleContainer>
                  <TextContainer $isCustomColor={isCustomColor} $customColor={customColor}>
                    <TextBlock
                      text={
                        templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedHome?.text ||
                        'Your Estimated Home Equity is the total amount of equity in your home.'
                      }
                      type={TypeOfText.Text}
                      isCustomColor={isCustomColor}
                      customColor={customColor}
                    />
                  </TextContainer>
                </InfoContainer>
                <InputContainer>
                  <TextInput size="10%" placeholder="$0" value={formatNumber(loanApplication?.estimatedRawEquity)} isRequired={false} />
                </InputContainer>
              </EquityGroup>
              <EquityGroup $isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.availableEquity?.isHidden ?? false}>
                <EstimatedAvailableIcon />
                <InfoContainer>
                  <TitleContainer>
                    <TextBlock
                      text={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.availableEquity?.title || 'Available Equity To Borrow'}
                      type={TypeOfText.H2}
                      isCustomColor={isCustomColor}
                      customColor={customColor}
                    />
                  </TitleContainer>
                  <TextContainer $isCustomColor={isCustomColor} $customColor={customColor}>
                    <TextBlock
                      text={
                        templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.availableEquity?.text ||
                        'Your available equity to borrow is an estimate based on program loan-to-value (LTV) criteria; keep in mind you may be eligible for more or less depending on program LTV limits.'
                      }
                      type={TypeOfText.Text}
                      isCustomColor={isCustomColor}
                      customColor={customColor}
                    />
                  </TextContainer>
                </InfoContainer>
                <InputContainer>
                  <TextInput size="10%" placeholder="$0" value={formatNumber((cltvPercentage / 100) * loanApplication?.estimatedRawEquity)} isRequired={false} />
                </InputContainer>
              </EquityGroup>
            </>
          )}
        </Wrapper>
      </Container>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.EstimatedAvailableEquity?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.EstimatedAvailableEquity?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.EstimatedAvailableEquity?.NavigationButton?.backButton?.text || 'Back'}
        disabled={error}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.GET_STARTED_ADDRESS}
        currentPage={PageOrigin.ESTIMATED_AVAILABLE_EQUITY}
        destinationPage={PageOrigin.VMO}
      />
    </>
  );
};

export default EstimatedAvailableEquity;
