import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    primary: '#5140E9',
    secondary: '#ff5733',
    bgSecondary: '#3A149E',
    bgSelectProgramButton: '#7363F1',
    hoverColor: '#a6ce39',
    default: '#5dade2',
    bgLightGrey: '#f9f9fa',
    bgMediumGrey: '#EDF1F4',
    bgWhite: '#ffffff',
    bgLightViolet: '#EEE3FF',
    sideBarColorSteps: '#ffffff',
    sideBarColorStepsChecked: '#ffffff',
    sidebarStrokeCircles: '#000000',
    fillCustomCircleCheck: '#ffffff',
    sideBarColorHeader: '#ffffff',
    colorCustomCircleChecked: '#000000',
    fillCustomCircleChecked: '#ffffff',
    bgGrey: '#5e6572',
    bgGreen: '#008000',
    bgSidebar: 'linear-gradient(136deg, #2f1758 -4.07%, #503ced 106.3%)',
    bgDarkGrey: '#333333',
    bgLightWhite: '#f8f9fa',
    bgLilacLd: '#5c2d9138',
    bgLightLilac: '#e1cfff',
    bgDetailsLd: '#ffffff40',
    borderLightLilac: '#e1cfff',
    bgGrey400: '#bfc4cd',
    bgSection: '#f9f2ff',
    bgValueProgram: '#7F6FFF',
    bgFooterProgramCard: '#EDF1F4',
    bgDisable: '#cccccc',
    borderColor: '#ced4da',
    borderColorFocus: '#5140E9',
    errorColor: '#ce3939',
    transparent: 'transparent',
    textPrimary: '#ffffff',
    textSecondary: '#000000',
    textGrey: '#5e6572',
    textOrange: '#ffa500',
    textLightGrey: '#C2C9D1',
    textSecondLightGrey: '#6C7584',
    bgProgressGrey: '#D9D9D9',
    textPrimaryLd: '#3A149E',
    textDarkGrey: '#273141',
    textLightLilac: '#B1ACD3',
    progressBarSecond: '#7A59D0',
    progressBarThirdColor: '#B3D8AD',
    textTertiary: '#3f4252',
  },
  fonts: {
    main: 'poppins',
  },
  breakpoints: {
    xs: '380px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};
