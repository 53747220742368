import React, { useState, useEffect } from 'react';
import { Wrapper, FormGroup, TitleContainer } from '../wrapper.styles';
import { TextInput } from '../../components/input/TextInput/TextInput';
import { TextBlock } from '@components/text/TextBlock';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { DropDownInput } from '@components/input/DropDownInput/DropDownInput';
import { pages } from '../../defaultVerbiages';
import { RadioButton } from '@components/input/RadioButton/RadioButton';
import { AddressAutoComplete } from '@components/input/AddressAutoComplete/AddressAutoComplete';
import DataPicker from '@components/input/DatePicker/DatePicker';
import { CheckConsent } from '@components/input/CheckConsent/CheckConsent';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { FormGroupComponent } from '@components/input/FormGroup/FormGroup';
import { PhoneInput } from '@/components/input/PhoneInput/PhoneInput';
import { TypeOfText } from '@components/text/TextBlock';
import _ from 'lodash';
import { ModalSubmit } from '@/components/Modals/ModalSubmit/ModalSubmit';
interface GetStartedProps {
  onAccess: (page: string) => void;
}

export enum PropertyType {
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  Suffix = 'suffix',
  Email = 'email',
  PurposeOfLoan = 'purposeOfLoan',
  Address = 'address',
  Suit = 'suit',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  DataOccupancyDate = 'dataOccupancyDate',
  DateBirthday = 'dateBirthday',
  Phone = 'phone',
  MailingSuit = 'mailingSuit',
  MailingCity = 'mailingCity',
  MailingState = 'mailingState',
  MailingZip = 'mailingZip',
  PrimaryUse = 'primaryUse',
  PropertyType = 'propertyType',
}
const GetStarted: React.FC<GetStartedProps> = ({ onAccess }) => {
  const dispatch = useAppDispatch();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const { openSoftPull, isResume } = useAppSelector((state) => state.currentPage);
  const {
    firstName,
    eConsent,
    middleName,
    lastName,
    suffix,
    email,
    purposeOfLoan,
    suit,
    city,
    primaryUse,
    state,
    zip,
    dataOccupancyDate,
    propertyType,
    mailingCity,
    mailingState,
    mailingZip,
    address,
  } = useAppSelector((state) => state.CurrentForm);
  const [isMailingAddressSame, setIsMailingAddressSame] = useState(true);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [selectedPrimaryUse, setSelectedPrimaryUse] = React.useState('');
  const [formError, setFormError] = React.useState(false);
  const { propertyStreetAddress, propertyStreetAddress2 } = loanApplication?.borrowerProperty || {};

  const [formValues, setFormValues] = useState({
    firstName: firstName || '',
    middleName: middleName || '',
    lastName: lastName || '',
    suffix: suffix || '',
    email: email || '',
    purposeOfLoan: purposeOfLoan || '',
    address: propertyStreetAddress || address || '',
    suit: propertyStreetAddress2 || '',
    city: city || '',
    state: state || '',
    zip: zip || '',
    mailingSuit: propertyStreetAddress2 || '',
    mailingCity: mailingCity || '',
    mailingState: mailingState || '',
    mailingZip: mailingZip || '',
    primaryUse: primaryUse || '',
    propertyType: propertyType || '',
    dataOccupancyDate: dataOccupancyDate || '',
  });

  const isICE = templateConfig.isICE ?? false;
  const [isErrorForm, setIsErrorForm] = useState(false);

  useEffect(() => {
    if (templateConfig && Object.keys(templateConfig).length && templateConfig?.lenderId) {
      const payload = {
        lenderId: templateConfig?.lenderId,
        configurationId: templateConfig?.configurationId,
      };

      dispatch({
        type: 'LoanApplication/updateLoanApplication',
        payload: payload,
      });
    }
  }, [templateConfig, dispatch]);

  const requiredValues = {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    email: formValues.email,
    purposeOfLoan: formValues.purposeOfLoan,
    PrimaryUse: formValues.primaryUse,
    PropertyType: formValues.propertyType,
    address: formValues.address,
    city: formValues.city,
    state: formValues.state,
    zip: formValues.zip,
    dataOccupancyDate: formValues.dataOccupancyDate,
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(requiredValues).every((value) => (typeof value === 'boolean' ? value : value.trim() !== ''));

    setFormError(allFieldsFilled);
  }, [formValues, requiredValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const isUnitType = templateConfig?.pages?.LetsGetStarted?.consumerInformation?.unitType?.isHidden ?? false;

  const handleChange = _.debounce((value: string | number | boolean, name: string) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    let obj: any = {};
    obj[name] = value;
    dispatch({ type: 'CurrentForm/addField', payload: { ...formValues, [name]: value } });
  }, 100);

  useEffect(() => {
    const { propertyCity, propertyState, propertyStreetAddress, propertyZip, propertyStreetAddress2 } = loanApplication?.borrowerProperty || {};
    setFormValues((prevValues) => ({
      ...prevValues,
      city: propertyCity || prevValues.city,
      state: propertyState || prevValues.state,
      address: propertyStreetAddress || prevValues.address,
      zip: propertyZip || prevValues.zip,
      suit: propertyStreetAddress2 || prevValues.suit,
    }));
  }, [loanApplication]);
  const protectInformation = {
    header: 'How we protect your data...',
    body: 'We only request and share the information that is required to provide you with your Home Equity Report and a loan pre-qualification determination. All information is encrypted and secure. We not only follow applicable laws, but also industry best practices that go above and beyond whats required to ensure we are at the forefront of the industry in protecting our customers. We adhere to the following privacy principles: We collect and use customers information only as necessary to conduct business. We take steps to prevent identity theft and guard against unauthorized transactions, including engaging qualified industry leading third-party service providers to assist with security services.We only do business with third-parties when we have confirmed such third-party has the appropriate policies, procedures, and systems in place to meet our rigorous security and privacy standards.',
    button: 'Close',
  };

  const infoSoftPull = {
    type: 'submit',
    header: '',
    body: 'I hereby authorize loanDepot to obtain my personal credit report in compliance with the Fair Credit Reporting Act. I authorize Columbia Credit Union to obtain such information solely for a pre-qualification for credit and this soft credit inquiry will not affect my credit score.',
    button: 'Close',
  };
  const infoEconsent = {
    type: 'confirm',
    header: 'eConsent Document',
    acceptButton: 'Read Econsent',
    button: 'Close',
    body: 'This document contains information about electronic communications. To read the full eConsent document please click the button bellow',
    link: 'https://docs.google.com/gview?embedded=true&url=https://start.loandepot.com/HELOC/forms/loandepot_heloc_econsent.pdf',
  };

  const handleMailingAddressCheckbox = (checked: boolean, type: string) => {
    if (type !== 'mailing') {
      handleChange(checked, type);
    } else {
      setIsMailingAddressSame(checked);
    }
  };
  const compareBeValue = (beValue: string) => {
    const property = templateConfig?.pages?.LetsGetStarted?.consumerInformation?.radioButton?.propertyType.find((property) => property.beValue.includes(beValue));

    if (property) {
      return beValue;
    }
    switch (beValue) {
      case 'Single Family':
        return 'SFR';
      case 'Multi Family':
        return '2 UNIT';
      case 'Condo':
        return 'LOW RISE CONDO';
      default:
        return beValue;
    }
  };
  const isNavigationButtonDisabled = !formError || !eConsent || !isErrorForm;
  useEffect(() => {
    setFormValues({
      firstName: firstName || '',
      middleName: middleName || '',
      lastName: lastName || '',
      suffix: suffix || '',
      email: email || '',
      purposeOfLoan: purposeOfLoan || '',
      address: propertyStreetAddress || address || '',
      suit: suit || '',
      city: city || '',
      state: state || '',
      zip: zip || '',
      mailingSuit: propertyStreetAddress2 || '',
      mailingCity: mailingCity || '',
      mailingState: mailingState || '',
      mailingZip: mailingZip || '',
      primaryUse: primaryUse ? primaryUse.toUpperCase() : '',
      propertyType: compareBeValue(propertyType) || '',
      dataOccupancyDate: dataOccupancyDate || '',
    });
    setSelectedPrimaryUse(compareBeValue(propertyType));
    setSelectedOption(primaryUse);
  }, [isResume]);
  return (
    <>
      <TitleContainer data-testid="title-container">
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.pageContainer?.title?.title || 'We’ll help you reach your goal!'}
          direction={'center'}
          color="primary"
          type={TypeOfText.H1}
          isHidden={templateConfig?.pages?.LetsGetStarted?.pageContainer?.title?.isHidden ?? false}
        />
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.pageContainer?.subtitle?.title || 'From applying to closing, we’ll help you every step of the way.'}
          direction={'center'}
          color="default"
          type={TypeOfText.Subtitle}
          isHidden={templateConfig?.pages?.LetsGetStarted?.pageContainer?.subtitle?.isHidden ?? false}
        />
      </TitleContainer>
      <Wrapper $gap="0.6rem">
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consumerInformation?.text || 'Consumer Information'}
          direction={'flex-start'}
          color="default"
          type={TypeOfText.H2}
          isHidden={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consumerInformation?.isHidden ?? false}
        />
        <FormGroupComponent onChange={handleChange}>
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.basicInformation?.text || 'Let’s get some basic information to get started.'}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.basicInformation?.isHidden ?? false}
          />
          <TextInput
            propertyType={PropertyType.FirstName}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.firstName?.text || 'First Name'}
            typeOfIcon="text"
            size={'32%'}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.firstName?.isHidden ?? false}
            value={firstName || ''}
          />
          <TextInput
            propertyType={PropertyType.MiddleName}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.middleName?.text || 'Middle Initial'}
            typeOfIcon="text"
            size={'20%'}
            isRequired={false}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.middleName?.isHidden ?? false}
            value={middleName || ''}
          />
          <TextInput
            propertyType={PropertyType.LastName}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.lastName?.text || 'Last Name'}
            typeOfIcon="text"
            size={'32%'}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.lastName?.isHidden ?? false}
            value={lastName || ''}
          />
          <DropDownInput
            propertyType={PropertyType.Suffix}
            placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suffix?.title || 'Suffix'}
            options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suffix?.options}
            isRequired={false}
            size={'10%'}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suffix?.isHidden ?? false}
            capitalizeWord={false}
          />
          <TextInput
            propertyType={PropertyType.Email}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.email?.text || 'Email Address'}
            size={'50%'}
            typeOfIcon="email"
            type="email"
            setIsErrorForm={setIsErrorForm}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.email?.isHidden ?? false}
            value={email || ''}
          />
          <PhoneInput
            propertyType={PropertyType.Phone}
            placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.titleInput || '(000) 000-0000'}
            label={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.titleDropDown || 'Select'}
            size={'30%'}
            type="phone"
            options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.isHidden ?? false}
          />
          <DropDownInput
            propertyType={PropertyType.PurposeOfLoan}
            placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.purposeOfLoan?.text || 'Purpose of loan'}
            options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.purposeOfLoan?.options}
            size={'30%'}
            typeOfIcon="dollar"
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.purposeOfLoan?.isHidden ?? false}
          />
        </FormGroupComponent>
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.protectInformation?.text || '<p>Learn how we protect your information</p>'}
          direction={'center'}
          color="primary"
          type={TypeOfText.H4}
          isLinkModal={true}
          infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.protectInformation || protectInformation}
          isHidden={templateConfig?.pages?.LetsGetStarted?.protectInformation?.isHidden ?? false}
        />
      </Wrapper>
      <Wrapper $gap="0.6rem">
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.PropertyInformation?.text || 'Property Information'}
          direction={'flex-start'}
          color="default"
          type={TypeOfText.H2}
          isHidden={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.PropertyInformation?.isHidden ?? false}
        />
        <FormGroupComponent onChange={handleChange} data-testid="address-form">
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.addressProperty?.text || 'What is the address of your property?'}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.addressProperty?.isHidden ?? false}
          />
          <AddressAutoComplete size="60%" onChange={handleChange} typeOfIcon="address" propertyType={PropertyType.Address} />
          <TextInput
            propertyType={PropertyType.Suit}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suit?.text || 'Unit/Suit'}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suit?.isHidden ?? false}
            size="20%"
            value={loanApplication?.borrowerProperty?.propertyStreetAddress2}
            isRequired={false}
          />
          <TextInput
            propertyType={PropertyType.City}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.text || 'City'}
            value={loanApplication?.borrowerProperty?.propertyCity}
            size="40%"
            setIsErrorForm={setIsErrorForm}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.isHidden ?? false}
          />
          <DropDownInput
            propertyType={PropertyType.State}
            placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.text || 'State'}
            options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.options}
            value={loanApplication?.borrowerProperty?.propertyState}
            size="5%"
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.isHidden ?? false}
            capitalizeWord={false}
          />
          <TextInput
            propertyType={PropertyType.Zip}
            value={loanApplication?.borrowerProperty?.propertyZip}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.text || 'Zip'}
            size="20%"
            setIsErrorForm={setIsErrorForm}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.isHidden ?? false}
          />
        </FormGroupComponent>
        <CheckConsent
          type="mailing"
          text={templateConfig?.pages?.LetsGetStarted?.propertyMailingAddress?.checkText || 'This property is also my mailing address.'}
          direction="center"
          checked={true}
          onChange={handleMailingAddressCheckbox}
          isHidden={templateConfig?.pages?.LetsGetStarted?.propertyMailingAddress?.isHidden ?? false}
        />
        {!isMailingAddressSame && (
          <>
            <TextBlock
              text={templateConfig?.pages?.LetsGetStarted?.propertyMailingAddress?.information || 'Please provide your mailing address'}
              direction={'flex-start'}
              color="default"
              type={TypeOfText.H3}
            />
            <FormGroupComponent onChange={handleChange} data-testid="address-form">
              <AddressAutoComplete size="60%" typeOfIcon="address" isMailingAddress={true} />
              <TextInput propertyType={PropertyType.Suit} placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suit?.text || 'Unit/Suit'} size="20%" />
              {!isUnitType ? (
                <DropDownInput
                  propertyType={PropertyType.MailingSuit}
                  placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.unitType?.text || 'Unit Type'}
                  options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.unitType?.options}
                  size="12%"
                  isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.unitType?.isHidden ?? false}
                />
              ) : (
                <></>
              )}
              <TextInput
                propertyType={PropertyType.MailingCity}
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.text || 'City'}
                value={loanApplication?.mailingAddress?.mailingCity}
                size="40%"
                isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.isHidden ?? false}
              />
              <DropDownInput
                propertyType={PropertyType.MailingState}
                placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.text || 'State'}
                options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.options}
                value={loanApplication?.mailingAddress?.mailingState}
                size="12%"
                isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.isHidden ?? false}
              />
              <TextInput
                propertyType={PropertyType.MailingZip}
                value={loanApplication?.mailingAddress?.mailingZip}
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.text || 'Zip'}
                size="20%"
                isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.isHidden ?? false}
              />
            </FormGroupComponent>
          </>
        )}
        <FormGroup>
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.propertyType?.text || 'Whats your property type?'}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isInfoModal={true}
            infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.exampleModal || infoSoftPull}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.propertyType?.isHidden ?? false}
          />
          {templateConfig?.pages?.LetsGetStarted?.consumerInformation?.radioButton?.propertyType.map(
            (propertyUseValue, index) =>
              !propertyUseValue.isHidden && (
                <RadioButton
                  onChange={handleChange}
                  propertyType={PropertyType.PropertyType}
                  key={index}
                  value={propertyUseValue.text}
                  beValue={propertyUseValue.beValue}
                  width={propertyUseValue.width}
                  selectedOption={selectedPrimaryUse}
                  setSelectedOption={setSelectedPrimaryUse}
                  checked={selectedPrimaryUse === propertyUseValue.beValue}
                />
              ),
          )}
        </FormGroup>
        <FormGroup>
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.useOfProperty?.text || 'Whats the use of this property?'}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.useOfProperty?.isHidden ?? false}
          />
          {templateConfig?.pages?.LetsGetStarted?.consumerInformation?.radioButton?.propertyUse.map(
            (propertyUseValue, index) =>
              !propertyUseValue.isHidden && (
                <RadioButton
                  onChange={handleChange}
                  propertyType={PropertyType.PrimaryUse}
                  key={index}
                  value={propertyUseValue?.text}
                  beValue={propertyUseValue.beValue}
                  width={propertyUseValue?.width}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  checked={selectedOption === propertyUseValue.beValue}
                />
              ),
          )}
        </FormGroup>
        <FormGroup>
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.purchaseDate?.text || 'What was the purchase date of this property?'}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isInfoModal={true}
            infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.exampleModal || infoSoftPull}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.purchaseDate?.isHidden ?? false}
          />
          <DataPicker
            propertyType={PropertyType.DataOccupancyDate}
            onChange={handleChange}
            propertyName={'propertyOccupancyDate'}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.purchaseDate?.text || 'MM/YYYY'}
            typeOfIcon="date"
            setIsErrorForm={setIsErrorForm}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.purchaseDate?.isHidden ?? false}
          />
        </FormGroup>
      </Wrapper>
      <Wrapper $gap="1rem">
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consent?.text || 'Consent'}
          isHidden={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consent?.isHidden ?? false}
          direction={'flex-start'}
          color="default"
          type={TypeOfText.H2}
        />
        {!isICE && (
          <CheckConsent
            type={'softPullConsent'}
            text={templateConfig?.pages?.LetsGetStarted?.softPull?.text || 'I agree to allow the Lender to conduct a softpull on my credit.'}
            isInfoModal={true}
            infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.infoSoftPull || infoSoftPull}
            direction={'center'}
            onChange={handleMailingAddressCheckbox}
            isHidden={templateConfig?.pages?.LetsGetStarted?.softPull?.isHidden ?? false}
          />
        )}
        <CheckConsent
          type={'eConsent'}
          text={templateConfig?.pages?.LetsGetStarted?.eConsent?.text || 'I agree to provide eConsent allowing the Lender to contact me electronically about my loan inquiry.'}
          direction={'center'}
          isInfoModal={true}
          infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.infoEconsent || infoEconsent}
          onChange={handleMailingAddressCheckbox}
          isHidden={templateConfig?.pages?.LetsGetStarted?.eConsent?.isHidden ?? false}
        />
      </Wrapper>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.LetsGetStarted?.NavigationButton?.nextButton || 'Next'}
        backButton={templateConfig?.pages?.LetsGetStarted?.NavigationButton?.backButton?.text || 'Back'}
        isBackButton={templateConfig?.pages?.LetsGetStarted?.NavigationButton?.backButton?.isHidden ?? false}
        disabled={isNavigationButtonDisabled}
        onAccess={onAccess}
        currentPage={PageOrigin.GET_STARTED}
        type="submit"
      />
      {openSoftPull && <ModalSubmit openModal={openSoftPull} setOpenModal={() => {}} infoSubmitModal={pages?.LetsGetStarted?.modals?.infoSubmitModal} onAccess={onAccess} />}
    </>
  );
};

export default GetStarted;
