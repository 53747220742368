import styled, { css } from 'styled-components';
import ActiveSvg from '../../../assets/images/circle-default.svg?react';
import CheckedSvg from '../../../assets/images/circle-checked.svg?react';
import InfoIcons from '../../../assets/images/info-icons.svg?react';
export type CheckConsentProps = {
  $direction?: string;
  width?: string;
  $checkIconColor?: string;
  $checkIconBackground?: string;
  $infoIconColor?: string;
  $isHidden?: boolean;
  $coapplicantConsent?: boolean;
};

const IconsSize = css`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const AlignText = css`
  display: flex;
  align-items: center;
`;

export const Col = styled.div<CheckConsentProps>`
  flex: ${({ $coapplicantConsent }) => ($coapplicantConsent ? 'none' : '1 1 0')};
  width: 100%;
  display: flex;
  align-items: ${({ $direction }) => $direction};
  font-size: 1.5rem;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 0rem;
  }
`;

export const ContainerInfoIcon = styled.div<CheckConsentProps>`
  height: 50%;
  ${AlignText};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: 0.1rem;
    align-items: stretch;
  }
`;

export const ContainerIcon = styled.div<CheckConsentProps>`
  height: 80%;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  align-items: center;
`;
export const ContainerCheckConsent = styled.div<CheckConsentProps>`
  height: 100%;
  align-items: ${({ $direction }) => $direction};
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  a {
    color: ${({ theme }) => theme.colors.bgSecondary};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 1rem;
  }
`;
export const ActiveIcon = styled(ActiveSvg)`
  ${IconsSize};
  path {
    width: 1.25rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;
export const CheckedIcon = styled(CheckedSvg)<CheckConsentProps>`
  ${IconsSize};
  margin-right: 0.5rem;
  fill: ${({ theme }) => theme.colors.bgGreen};
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  .circle-color {
    fill: ${({ $checkIconColor, theme }) => ($checkIconColor ? $checkIconColor : theme.colors.bgWhite)};
  }
`;

export const InfoIcon = styled(InfoIcons)<CheckConsentProps>`
  width: 1.18rem;
  height: 1.18rem;
  margin-left: 1rem;
  cursor: pointer;
  path {
    fill: ${({ $infoIconColor, theme }) => ($infoIconColor ? $infoIconColor : theme.colors.primary)};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 0.3rem;
  }
`;
