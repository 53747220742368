import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col, EditIcon } from './PropertyCard.styles';
import { useAppSelector } from '@app/hooks';
import { EditModal } from '../../Modals/EditModal/EditModal';
import { pages } from '../../../defaultVerbiages';

export const PropertyCard = () => {
  const loanApplication = useAppSelector((state) => state.LoanApplication);
  const [openModal, setOpenModal] = useState(false);
  const propertyLatitude = loanApplication?.borrowerProperty?.propertyLatitude;
  const propertyLongitude = loanApplication?.borrowerProperty?.propertyLongitude;
  const imageLink = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${propertyLatitude},${propertyLongitude}&key=AIzaSyCPnpRM8pmiQYYugiLCpMZ_2ZmnT58uXbE`;
  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  const handleModal = () => {
    setOpenModal(true);
  };
  return (
    <CardBody $isHiddenMap={templateConfig?.pages?.LetsGetStartedAddress?.map?.isHidden ?? false}>
      <img src={imageLink} className="property-image" alt="Image of property from google street view" />
      <Card $isHidden={templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.isHidden ?? false}>
        <CardHeader>
          <h5>{templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.title || 'My Property'}</h5>
          <EditIcon data-testid="edit-icon" onClick={handleModal} />
          {openModal && <EditModal openModal={openModal} setOpenModal={setOpenModal} infoModal={pages.LetsGetStartedAddress.modals.editAddress} />}
        </CardHeader>
        <Row>
          <Col>
            <p>{templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.address || 'Address'}</p>
            <h5>{loanApplication?.borrowerProperty?.propertyStreetAddress}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.city || 'City'}</p>
            <h5>{loanApplication?.borrowerProperty?.propertyCity}</h5>
          </Col>
          <Col>
            <p>{templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.state || 'State'}</p>
            <h5>{loanApplication?.borrowerProperty?.propertyState}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.country || 'Country'}</p>
            <h5>{loanApplication?.borrowerProperty?.propertyCountry}</h5>
          </Col>
          <Col>
            <p>{templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.zip || 'Zip'}</p>
            <h5>{loanApplication?.borrowerProperty?.zip}</h5>
          </Col>
        </Row>
      </Card>
    </CardBody>
  );
};
