import React, { useEffect, useState } from 'react';
import { NavBar } from '@components/NavBar/NavBar';
import { SideBar } from '@components/SideBar/SideBar';
import { AppContainer, ContentContainer, Spinner, WrapperLoading, WrapperResume } from './App.styles';
import { Footer } from '@components/Footer/Footer';
import { MainContent } from '@components/Main/Main';
import FullStory from 'react-fullstory';
import { useAppSelector } from '@app/hooks';
import Resume from '@/components/Resume/Resume';
import { Helmet } from 'react-helmet';
import useInjectScripts from '@/hooks/useInjectScripts';

const App: React.FC = () => {
  const TemplateConfig = useAppSelector((state) => state.TemplateConfig);
  const { isResume } = useAppSelector((state) => state.currentPage);
  const environment = import.meta.env.VITE_APP_ENV;
  const fullStoryId = TemplateConfig?.fullStoryId?.[environment] || import.meta.env.VITE_FS_ID;
  const [loading, setLoading] = useState(true);

  const isTemplateConfig = Boolean(TemplateConfig && Object.keys(TemplateConfig).length > 0);
  const isResumeV2 = TemplateConfig?.features?.resume?.isEnabled ?? true;
  const isTemplateResume = isResume && isResumeV2;
  const favicon = TemplateConfig?.globalTheme?.favicon || '';
  const htmlScripts = TemplateConfig?.features?.htmlScripts || { isEnabled: false, scripts: [] };
  const { renderScripts } = useInjectScripts(htmlScripts);

  useEffect(() => {
    if (TemplateConfig?.lenderId && isTemplateConfig) {
      setLoading(false);
    }
  }, [TemplateConfig, isTemplateConfig]);

  return (
    <>
      <Helmet>
        {favicon && <link rel="icon" href={favicon} />}
        {renderScripts()}
      </Helmet>
      <WrapperLoading $loading={loading} $isResume={isTemplateResume}>
        <Spinner $loading={loading} $isResume={isTemplateResume} />
      </WrapperLoading>
      <WrapperResume $loading={loading} $isResume={isTemplateResume}>
        <Resume />
        <Footer variant="primary" />
      </WrapperResume>
      <AppContainer $loading={loading} $isResume={isTemplateResume}>
        <SideBar variant="primary" />
        <ContentContainer>
          {fullStoryId && <FullStory org={fullStoryId} />}
          <NavBar variant="primary" />
          <MainContent variant="primary" />
          <Footer variant="primary" />
        </ContentContainer>
      </AppContainer>
    </>
  );
};

export default App;
