import styled, { css } from 'styled-components';
interface FormGroupProps {
  $direction?: string;
  $gap?: string;
  $paddingButton?: string;
}

interface WrapperProps {
  $gap?: string;
  $minHeight?: string;
  $isMobile?: boolean;
  $padding?: string;
}

const AlignText = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Wrapper = styled.section<WrapperProps>`
  position: relative;
  ${AlignText};
  justify-content: center;
  background: ${({ theme }) => theme.colors.bgWhite};
  margin: 1.5rem 1.5rem;
  padding: ${({ $isMobile, $padding }) => ($isMobile ? ($padding ? $padding : '1.25rem') : '1.563rem')};
  border-radius: 0.688rem;
  min-height: ${({ $minHeight }) => ($minHeight ? $minHeight : '9rem')};
  gap: ${({ $gap }) => ($gap ? $gap : '1.875rem')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: ${({ $gap }) => ($gap ? $gap : '1.85rem')};
  }
`;

export const FormGroup = styled.section<FormGroupProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: ${({ $direction }) => $direction};
  gap: ${({ $gap }) => ($gap ? $gap : '1.313rem')};
  padding-bottom: ${({ $paddingButton }) => ($paddingButton ? $paddingButton : '1.875rem')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    gap: ${({ $gap }) => ($gap ? $gap : '0.8rem')};
  }
`;

export const TitleContainer = styled.section<FormGroupProps>`
  ${AlignText};
  flex-wrap: wrap;
  padding: 2.188rem 1.563rem;
  gap: ${({ $gap }) => ($gap ? $gap : '2.188rem')};
  margin: 1.5rem 1.5rem;
  flex-direction: ${({ $direction }) => $direction};
  background: ${({ theme }) => theme.colors.bgWhite};
  border-radius: 0.688rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: ${({ $gap }) => ($gap ? '0rem' : '0.8rem')};
    padding: 1.375rem 1.563rem;
  }
`;
