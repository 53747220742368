import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export interface ModalSubmitProps {
  $openModal?: string;
  $isLoading?: boolean;
  $isOpen?: boolean;
}

const FlexStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackDrop = styled.div<ModalSubmitProps>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1040;
  transition: opacity 0.3s ease;
  opacity: 1;
  pointer-events: auto;
`;

export const Modal = styled.div<ModalSubmitProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.625rem 3.125rem #00000080;
  z-index: 1050;
  max-width: ${(props) => (props.$isLoading ? '27.875rem' : '48rem')};
  min-width: 21.875rem;
  overflow-y: auto;
  gap: 2.938rem;
`;

export const ModalTitle = styled.div<ModalSubmitProps>`
  max-height: 20vh;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 1.563rem;
  ${FlexStyle};
  font-family: Poppins;
  color: ${(props) => props.theme.colors.textPrimaryLd};
  padding-bottom: ${(props) => (props.$isLoading ? '4rem' : '1rem')};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.25rem;
  }
`;

export const ModalBody = styled.div`
  margin-bottom: 0;
  ${FlexStyle};
  font-family: Poppins;
  flex-direction: column;
  padding: 3.125rem;
  text-align: center;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;

export const ModalInput = styled.div`
  margin-bottom: 0.5rem;
  ${FlexStyle};
  flex-direction: column;
  font-family: Poppins;
  line-height: 1.375rem;
  white-space: normal;
  font-size: 1.25rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.9rem;
  }
`;

export const ModalContent = styled.div<ModalSubmitProps>`
  max-height: 50vh;
  font-weight: 100;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  ${FlexStyle};
  flex-wrap: wrap;
  flex-direction: column;
  font-family: Poppins;
  line-height: 1.375rem;
  white-space: normal;
  color: ${(props) => props.theme.colors.textDarkGrey};
  p {
    color: ${({ theme }) => theme.colors.textDarkGrey};
    text-align: center;
    font-family: Poppins;
    font-size: 1.063rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 1rem;
    }
  }
`;

export const ModalFooter = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  nav {
    margin: 0;
  }
`;

export const Spinner = styled.div`
  width: 3.438rem;
  height: 3.438rem;
  margin-bottom: 3rem;
  border: 0.375rem solid ${({ theme }) => theme.colors.bgWhite};
  border-top: 0.375rem solid ${({ theme }) => theme.colors.bgSecondary};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const Divider = styled.div`
  margin: 0.5rem 0;
`;
