import React from 'react';
import { NavBarWrapper, Brand, NavLoanInfoText, Col, SupportPhone } from './NavBar.styles';
import { useAppSelector } from '@app/hooks';
import { useIsMobile } from '@/hooks/useIsMobile';
import { handleCurrentVmoPage } from '@/utils/handleCurrentPage';

export interface NavBarProps {
  variant: 'primary' | 'secondary' | 'default';
  storybookLabel?: {
    pageLabel?: string;
    helpLabel?: string;
    supportLabel?: string;
    loanLabel?: string;
  };
  color?: string;
  backgroundColor?: string;
  isCustomColor?: boolean;
  isGetStarted?: boolean;
  isStartedPage?: boolean;
}

export const NavBar: React.FC<NavBarProps> = ({
  variant = 'primary',
  storybookLabel,
  color = '#ffffff',
  isCustomColor = false,
  backgroundColor = '#5140E9',
  isStartedPage = true,
}) => {
  const currentPage = useAppSelector((state) => state.currentPage.step);
  const isMobile = useIsMobile();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const isICE = templateConfig.isICE ?? false;
  const frontProgramType = loanApplication?.selectedProgramType === 'Line of Credit' ? 'helocData' : 'helData';
  const isHelProgram = frontProgramType === 'helData' || isICE;
  const hasDrawPage = (templateConfig?.pages?.hasDrawAmountPage || !isHelProgram) ?? false;
  const { isEnabled: isVmoRedesignEnabled = false } = templateConfig?.features?.vmoRedesign ?? {};
  const hasDeclarations = !!templateConfig?.pages?.declarations;
  const pagesWithVmoVersion = handleCurrentVmoPage(isVmoRedesignEnabled, hasDrawPage, hasDeclarations);

  const isGetStarted = templateConfig?.pages?.pages?.[currentPage] === templateConfig?.pages?.pages?.[0] && currentPage === 0 && isStartedPage;
  const isLastPageOnMobile = pagesWithVmoVersion[currentPage] !== 'Completion' && isMobile;
  let isVmoPage;
  const getPageName = () => {
    const pageName = storybookLabel?.pageLabel || pagesWithVmoVersion[currentPage];
    isVmoPage = pageName === 'View My Offer! (Available Programs)';
    return isMobile && isVmoPage ? 'View My Offer!' : pageName;
  };
  const isPhoneHelp = pagesWithVmoVersion?.[currentPage] !== 'Completion' && !isMobile && templateConfig?.pages?.navBar?.phoneHelp;

  const showConnectSupport = storybookLabel?.loanLabel || !templateConfig?.pages?.navBar?.connectSupport?.isHidden;
  const connectSupportText = storybookLabel?.loanLabel || templateConfig?.pages?.navBar?.connectSupport?.text || '';
  const phoneHelpText = `${storybookLabel?.loanLabel || templateConfig?.pages?.navBar?.phoneHelp?.text || ''} ${templateConfig?.phoneContact || ''}`;

  return (
    <NavBarWrapper $variant={variant} data-testid="nav-bar" $backgroundColor={backgroundColor} $isCustomColor={isCustomColor} $isGetStarted={isGetStarted}>
      <Brand $variant={variant} data-testid="brand-bar" color={color} $isCustomColor={isCustomColor}>
        {getPageName()}
      </Brand>
      {isPhoneHelp && (
        <SupportPhone>
          <NavLoanInfoText $isGetStarted={isGetStarted} $isHidden={templateConfig?.pages?.navBar?.phoneHelp?.isHidden ?? false}>
            <Col $variant={variant} color={color} $isCustomColor={isCustomColor}>
              {showConnectSupport && <p>{connectSupportText ?? ''}</p>}
              <p>{phoneHelpText ?? ''}</p>
            </Col>
          </NavLoanInfoText>
        </SupportPhone>
      )}
      {!isGetStarted && !isLastPageOnMobile && (
        <NavLoanInfoText $isGetStarted={isGetStarted} $isHidden={templateConfig?.pages?.navBar?.loanNumber?.isHidden ?? false}>
          <Col $variant={variant} color={color} $isCustomColor={isCustomColor}>
            {storybookLabel?.loanLabel || `${templateConfig?.pages?.navBar?.loanNumber?.text} ${loanApplication?.loanNumber}` || 'Loan Number: 12345'}
          </Col>
        </NavLoanInfoText>
      )}
    </NavBarWrapper>
  );
};
