import { TextIcon, EmailIcon, EmploymentIcon, EyeSlash, Eye } from '../components/input/TextInput/TextInput.styled';
import { DollarIcon, LocationIcon } from '@/components/input/DropDownInput/DrowDownInput.styles';
type IconType = 'text' | 'email' | 'employment' | 'dollar' | 'location' | 'eyeSlash' | 'eye';

export const handleIcons = (typeOfIcon: IconType) => {
  const icons: Record<IconType, JSX.Element> = {
    text: <TextIcon />,
    email: <EmailIcon />,
    employment: <EmploymentIcon />,
    dollar: <DollarIcon />,
    location: <LocationIcon />,
    eyeSlash: <EyeSlash />,
    eye: <Eye />,
  };
  const hasIcon = icons[typeOfIcon];

  return hasIcon ? hasIcon : null;
};
