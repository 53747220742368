import { useEffect } from 'react';

interface ScriptAttributes {
  [key: string]: string | boolean | undefined;
}

export interface ScriptConfig {
  src?: string;
  inlineContent?: string;
  isEnabled: boolean;
  async?: boolean;
  defer?: boolean;
  variables?: { [key: string]: string | null };
  attributes?: ScriptAttributes;
}

export interface ScriptsConfig {
  isEnabled: boolean;
  scripts: ScriptConfig[];
}

const useInjectScripts = (scriptsConfig: ScriptsConfig) => {
  const escapeScriptContent = (content: string): string => {
    return content
      .replace(/<\/script>/g, '<\\/script>')
      .replace(/<\//g, '<\\/')
      .replace(/\r?\n|\r/g, '');
  };

  const replaceVars = (content: string, variables: { [key: string]: string | null } = {}): string => {
    if (!content) return content;
    Object.keys(variables).forEach((key) => {
      const regex = new RegExp(`\\{${key}\\}`, 'g');
      content = content.replace(regex, variables[key] || '');
    });
    return escapeScriptContent(content);
  };

  const renderScripts = () => {
    if (!scriptsConfig?.isEnabled) return null;

    return scriptsConfig.scripts
      .filter((script) => script.isEnabled && !script.inlineContent)
      .map((script, index) => {
        const src = replaceVars(script.src || '', script.variables);

        return <script key={index} src={src} type="text/javascript" async={script.async} defer={script.defer !== undefined ? script.defer : true} {...script.attributes} />;
      });
  };

  useEffect(() => {
    if (!scriptsConfig?.isEnabled) return;

    const injectedScripts: HTMLScriptElement[] = [];

    scriptsConfig.scripts
      .filter((script) => script.isEnabled && script.inlineContent)
      .forEach((script) => {
        const inlineContent = replaceVars(script.inlineContent || '', script.variables);
        const inlineScript = document.createElement('script');
        inlineScript.type = 'text/javascript';
        inlineScript.defer = script.defer !== undefined ? script.defer : true;
        inlineScript.async = script.async || false;

        inlineScript.textContent = inlineContent;

        if (script.attributes) {
          Object.entries(script.attributes).forEach(([key, value]) => {
            if (typeof value === 'boolean') {
              if (value) inlineScript.setAttribute(key, '');
            } else if (value) {
              inlineScript.setAttribute(key, value);
            }
          });
        }

        document.head.appendChild(inlineScript);
        injectedScripts.push(inlineScript);
      });

    return () => {
      injectedScripts.forEach((script) => {
        document.head.removeChild(script);
      });
    };
  }, [scriptsConfig]);

  return {
    renderScripts,
  };
};

export default useInjectScripts;
