import React from 'react';
import {
  LoanDetails,
  TitleContainer,
  WrapperContainer,
  Verbiage,
  Value,
  StepsContainer,
  WrapperButton,
  VerbiagePreQual,
  WrapperStepsContainer,
  TitleStepsContainer,
  WrapperText,
} from './CompletionV2.styles';
import { TextBlock, TypeOfText } from '../text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { parseSummaryValues } from '@/utils/parseInputValue';
import { Button } from '../Button/DefaultButton/Button';
import SafeHTML from '@/utils/safeHTML';
import { pages } from '@/defaultVerbiages';
interface CompletionCongratulationsPros {
  width?: string;
}

const CompletionV2: React.FC<CompletionCongratulationsPros> = ({ width = '100%' }) => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const infoCompletion = templateConfig?.pages?.completion || pages?.completion;

  return (
    <>
      <WrapperContainer>
        <TitleContainer width={width} data-testid="completion-congratulation">
          <TextBlock
            text={templateConfig?.pages?.completion?.title?.text || pages?.completion?.title?.text}
            direction={'center'}
            type={TypeOfText.H1}
            isHidden={templateConfig?.pages?.completion?.title?.isHidden || false}
          />
        </TitleContainer>
        <TitleContainer width={width}>
          <TextBlock
            text={templateConfig?.pages?.completion?.subTitle?.text || pages?.completion?.subTitle?.text}
            direction={'start'}
            color="primary"
            type={TypeOfText.H1}
            isHidden={templateConfig?.pages?.completion?.subTitle?.isHidden || true}
          />
        </TitleContainer>
        <LoanDetails>
          {infoCompletion?.loanDetails.map(
            (loan, index) =>
              !loan.isHidden && (
                <div key={index}>
                  <p>{`${loan.title}:`}</p>
                  <Value>{parseSummaryValues(loan.value, loanApplication)}</Value>
                </div>
              ),
          )}
        </LoanDetails>
        <VerbiagePreQual>
          <TextBlock
            text={templateConfig?.pages?.completion?.verbiage?.text || pages?.completion?.verbiage?.text}
            type={TypeOfText.Text}
            direction={'center'}
            isHidden={templateConfig?.pages?.completion?.verbiage?.isHidden || false}
          />
          <TextBlock
            text={templateConfig?.pages?.completion?.verbiageTwo?.text || pages?.completion?.verbiageTwo?.text}
            type={TypeOfText.H3}
            direction={'start'}
            isHidden={templateConfig?.pages?.completion?.verbiageTwo?.isHidden || false}
          />
        </VerbiagePreQual>
      </WrapperContainer>
      <WrapperStepsContainer>
        <TitleStepsContainer width={width} data-testid="completion-congratulation">
          <TextBlock
            text={templateConfig?.pages?.completion?.nextSteps?.title?.text || pages?.completion?.nextSteps?.title?.text}
            direction={'center'}
            type={TypeOfText.H1}
            isHidden={templateConfig?.pages?.completion?.nextSteps?.title?.isHidden || false}
          />
        </TitleStepsContainer>
        <StepsContainer>
          <SafeHTML tag="span" html={templateConfig?.pages?.completion?.nextSteps?.verbiage?.text || pages?.completion?.nextSteps?.verbiage?.text} />
        </StepsContainer>
        {templateConfig?.pages?.completion?.nextSteps?.isButtonPortal ? (
          <WrapperButton>
            <Button label={templateConfig?.pages?.completion?.nextSteps?.button?.text || pages?.completion?.nextSteps?.button?.text} variant="primary" onClick={() => {}} />
          </WrapperButton>
        ) : (
          <WrapperText>
            <TextBlock
              text={templateConfig?.pages?.completion?.nextSteps?.contact?.text || pages?.completion?.nextSteps?.contact?.text}
              direction={'start'}
              type={TypeOfText.Text}
              isHidden={templateConfig?.pages?.completion?.nextSteps?.contact?.isHidden || false}
            />
          </WrapperText>
        )}
        <Verbiage>
          <TextBlock
            text={templateConfig?.pages?.completion?.nextSteps?.verbiageLd?.textOne?.text || pages?.completion?.nextSteps?.verbiageLd?.textOne?.text}
            type={TypeOfText.Subtitle}
            isHidden={templateConfig?.pages?.completion?.nextSteps?.verbiageLd?.textOne?.isHidden || false}
          />
          <TextBlock
            text={templateConfig?.pages?.completion?.nextSteps?.verbiageLd?.textTwo?.text || pages?.completion?.nextSteps?.verbiageLd?.textTwo?.text}
            type={TypeOfText.Text}
            isHidden={templateConfig?.pages?.completion?.nextSteps?.verbiageLd?.textTwo?.isHidden || false}
          />
        </Verbiage>
      </WrapperStepsContainer>
    </>
  );
};

export default CompletionV2;
